import {
  Avatar,
  FormControl,
  Grid,
  IconButton,
  ImageListItem,
  InputAdornment,
  OutlinedInput,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { Dispatch, SetStateAction, useRef, useState } from 'react';
import SettingsOrglogo from './settings-org-logo';

type Props = {
  bio: string;
  logo: string;
  handleDetailsChange: (fieldName: any, newValue: any) => void;
  sendFile: (files: FileList) => Promise<void>;
  setSelectedImageFile: (files: FileList | null) => void;
  hasPermission: boolean;
  setIsUpdateEnabled: Dispatch<SetStateAction<boolean>>;
  checkStatus?: boolean;
};
const SettingsOrgbio = ({
  bio,
  logo,
  handleDetailsChange,
  setSelectedImageFile,
  hasPermission,
  setIsUpdateEnabled,
  checkStatus,
}: Props) => {
  const inputRef = useRef(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isBioExpanded, setIsBioExpanded] = useState(bio?.length <= 300);

  const handleToggleBioExpand = () => {
    setIsBioExpanded(!isBioExpanded);
  };

  const handleAvatarClick = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };

  const handleFileChange = event => {
    setSelectedImageFile(event.target.files);
    const file = event.target.files[0];

    setSelectedFile(file);
    setIsUpdateEnabled(true);
  };

  return (
    <Grid container spacing={2} sx={{ alignItems: 'center' }}>
      <Grid item xs={12} md={4}>
        <Typography variant="h5" sx={{ fontSize: '14px' }}>
          Bio & logo
        </Typography>
      </Grid>
      <Grid item xs={12} md={8} container spacing={2} sx={{ alignItems: 'center' }}>
        <Grid item xs={12} md={10}>
          <FormControl sx={{ width: '100%' }} variant="outlined">
            <OutlinedInput
              sx={{ fontSize: '0.75rem' }}
              id="outlined-adornment-weight"
              placeholder="Bio"
              multiline
              value={bio}
              onChange={e => handleDetailsChange('bio', e.target.value)}
              aria-describedby="outlined-weight-helper-text"
              rows={bio?.length > 200 ? 4 : 3}
              inputProps={{
                'aria-label': 'weight',
              }}
              disabled={!hasPermission}
            />
          </FormControl>
          {!checkStatus && <span style={{ color: '#E23A44', fontSize: '0.75rem' }}>Bio must be between 2 and 200 characters.</span>}
        </Grid>
        <Grid item xs={12} md={2}>
          <SettingsOrglogo
            hasPermission={hasPermission}
            inputRef={inputRef}
            selectedFile={selectedFile}
            setSelectedFile={setSelectedFile}
            handleAvatarClick={handleAvatarClick}
            handleFileChange={handleFileChange}
            logo={logo}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default SettingsOrgbio;
