import React, { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import { useTheme } from '@mui/material';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import { postingRulesType } from 'app/utils/types/activity/activity-types';
import axios from 'axios';

const CreateActivityPostingRules = ({ formik }) => {
  const [activityPostingRules, setActivityPostingRules] = useState<postingRulesType[]>([]);
  const theme = useTheme();

  useEffect(() => {
    if (formik.values.subCategoryID === null || formik.values.subCategoryID !== 14) {
      const fetchPostingRules = async () => {
        try {
          const response = await axios.get<postingRulesType[]>('api/rules-tables?subcategory=0');

          setActivityPostingRules(response.data);
        } catch (error) {
          console.log(error);
        }
      };
      fetchPostingRules();
    } else {
      const fetchPostingRules = async () => {
        try {
          const response = await axios.get<postingRulesType[]>('api/rules-tables?subcategory=14');

          setActivityPostingRules(response.data);
        } catch (error) {
          console.log(error);
        }
      };

      fetchPostingRules();
    }
  }, [formik.values.subCategoryID]);

  return (
    <Stack sx={{ backgroundColor: 'secondary.dark', borderRadius: '16px', p: 1, height: '110vh' }} gap={1}>
      <Typography
        sx={{ color: theme.textColor.mainHeading, fontSize: theme.p1.semiBold.fontSize, fontWeight: theme.p1.semiBold.fontWeight, px: 0.8 }}
      >
        Posting rules
      </Typography>
      <Stack direction="column" gap={1} sx={{ maxWidth: { xs: '100%', lg: 265 }, borderRadius: '15px', height: '66vh' }}>
        {activityPostingRules?.length
          ? activityPostingRules?.map(item => (
              <Stack key={item.id} direction="row" spacing={1} alignItems="flex-start" justifyContent={'flex-start'}>
                <Stack p={0.7}>
                  <Box
                    sx={{
                      width: '9px',
                      height: '9px',
                      borderRadius: '50%',
                      backgroundColor: '#775FFF',
                    }}
                  />
                </Stack>
                <Stack direction="row" sx={{ color: theme.textColor.paragraph, gap: 0.2, px: { xs: 0, lg: 2 } }}>
                  <Typography sx={{ fontSize: theme.infoText.regular.fontSize }}>{item.description}</Typography>
                </Stack>
              </Stack>
            ))
          : null}
      </Stack>
    </Stack>
  );
};

export default CreateActivityPostingRules;
