import React, { useEffect } from 'react';
import { Dialog, DialogActions, Button, Typography, TextField, useTheme } from '@mui/material';
import { useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import toast from 'react-hot-toast';
import { addDays } from 'date-fns';
import { useState } from 'react';
import { enUS } from 'date-fns/locale';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';
import { fetchCustomInstructions } from 'app/shared/reducers/volunteer';

type ValuePiece = Date | null;

type Value = ValuePiece | [ValuePiece, ValuePiece];
const RecurringRangeModal = ({ open, onClose, handleData }) => {
  const [filterId, setFilterId] = useState<number>(0);
  const [instructionsPayload, setInstructionsPayload] = useState<Record<string, string[]>>({});
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';

  const handleCancel = () => {
    onClose(filterId);
  };

  useEffect(() => {
    const fetchInstructions = async () => {
      if (handleData) {
        const parentData = handleData?.filter(item => item.parent);
        let response = await dispatch(fetchCustomInstructions({ activityId: parentData?.[0]?.id }));
        if (response && response.payload) {
          console.log('romal', response);
          const payload = response.payload as Record<string, string[]>;
          setInstructionsPayload(payload);

          const uniqueInstructionsData = Object.fromEntries(
            Object.entries(payload).map(([date, instructions]) => [date, Array.from(new Set(instructions))])
          );
        }
      }
    };

    fetchInstructions();
  }, [handleData, dispatch]);
  const filteredData = handleData?.filter(item => !item.parent);
  const startDateValues = filteredData?.map(item => new Date(item.startDate));
  const activeDates = startDateValues?.map(dateString => new Date(dateString));
  const isDateActive = date => {
    return activeDates.some(
      activeDate =>
        date.getFullYear() === activeDate.getFullYear() &&
        date.getMonth() === activeDate.getMonth() &&
        date.getDate() === activeDate.getDate()
    );
  };
  const handleDateClick = date => {
    const inputDate = new Date(date);
    const year = inputDate.getFullYear();
    const month = String(inputDate.getMonth() + 1).padStart(2, '0');
    const day = String(inputDate.getDate()).padStart(2, '0');
    const outputDateString = `${year}-${month}-${day}`;
    const data = handleData.filter(item => !item.parent);
    const filteredData = data.filter(item => {
      const startDateFormatted = new Date(item.startDate).toISOString();
      return outputDateString === startDateFormatted?.split('T')[0];
    });
    console.log('filteredData', filteredData);
    setFilterId(filteredData[0]?.id);
    // handleCancel();
  };
  useEffect(() => {
    if (filterId != 0) {
      handleCancel();
    }
  }, [filterId]);

  return (
    <Dialog open={open} onClose={handleCancel} maxWidth="xs">
      <style>
        {`.MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded.MuiPaper-elevation24.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthXs.css-1qxmuh4-MuiPaper-root-MuiDialog-paper {
              border-radius:27px !important;
            }`}

        {`
            button.react-calendar__navigation__label {
                       pointer-events: none;
          }`}
        {`.activeDate {
            color: white !important;
            border-radius:48% !important;
            padding :10px;
            background:#674BFF !important;
        }`}

        {/* {`.dimmedActiveDate {
          color: white !important;
          border-radius: 48% !important;
          padding: 10px;
          background: #66B2FF !important; 
}`} */}

        {`.dimmedActiveDate {
            background-color: #674bff;          /* Red circle */
            border-radius: 50%;             /* Make it circular */
            position: relative; 
            border: 2px solid red !important;
            color:white;
             pointer-events: none; /* Disables hover and click events */
        }`}
        {`.dimmedActiveDate::before {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 120%;                    /* Width to cover the circle diagonally */
            height: 1px;                   /* Thickness of the line */
            background-color: red;        /* White color for the line */
            transform: translate(-50%, -50%) rotate(45deg); /* Center and rotate the line */
            }`}

        {`.dimmedActiveDateParticipant  {
             background: #56C02B !important;
              border-radius: 50%;             /* Make it circular */
            position: relative; 
            border: 2px solid red !important;
            color:white;
             pointer-events: none; /* Disables hover and click events */
       }`}

        {`.dimmedActiveDateParticipant abbr::before {
         content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 120%;                    /* Width to cover the circle diagonally */
            height: 1px;                   /* Thickness of the line */
            background-color: red;        /* White color for the line */
            transform: translate(-50%, -50%) rotate(45deg); /* Center and rotate the line */
             
       }`}

        {`.activeDateParticipant abbr {
            color: #ffffff !important;
            background: #56C02B;
            padding: 20px;
         }`}

        {`.activeDateDotParticipant abbr {
            color: #ffffff !important;
            background: #56C02B;
            padding: 20px;
            position:relative
           
         }`}
        {`.activeDateDotParticipant abbr::before {
             content: '';
             width: 6px; /* Size of the dot */
             height: 6px;
            background-color: white; /* Color of the dot */
            border-radius: 50%; /* Make it circular */
            position: absolute;
            bottom: 19px; /* Adjust this value to position the dot */
            left: 50%; /* Center the dot horizontally */
            transform: translateX(-50%); /* Ensure perfect centering */
         }`}
        {`.activeDotDate {
            color: white !important;
            border-radius:48% !important;
            padding :10px;
            background:#674BFF !important;
             position:relative
        }`}

        {`.activeDotDate::before {
            content: '';
             width: 6px; /* Size of the dot */
             height: 6px;
            background-color: white; /* Color of the dot */
            border-radius: 50%; /* Make it circular */
            position: absolute;
            bottom: 4px; /* Adjust this value to position the dot */
            left: 50%; /* Center the dot horizontally */
            transform: translateX(-50%); /* Ensure perfect centering */
        }`}

        {`.react-calendar__tile {
             border-radius: 12px;
        }`}
        {`.react-calendar__tile:disabled {
        background-color: unset !important
        }`}

        {`.react-calendar {
      width: 100%;
      max-width: 100%;
      background: ${isDarkMode ? '#2f2f2f' : 'white'};
      border:unset;
      font-family: Arial, Helvetica, sans-serif;
      line-height: 2.125em;
      padding: 10px;
    }`}
        {`.react-calendar button {
      height: 42px;
      max-width: 42px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 6px 17px;
      border-radius:48% !important;
    }`}
        {`.css-1ec1dly-MuiPaper-root-MuiDialog-paper { 
      border-radius: 15px;
    }`}
        {`.react-calendar__navigation button:enabled:hover, .react-calendar__navigation button:enabled:focus {
    background-color: unset;
    }`}
        {`.react-calendar__navigation button{
    background-color: unset;
     color: ${isDarkMode ? 'white' : 'black'};`}

        {`react-calendar__navigation__label__labelText {
    pointer-events: none;
    }`}
        {`.react-calendar__navigation__label {
  pointer-events: none !important;
}`}
        {`button.react-calendar__navigation__label {
    pointer-events: none;
}`}
      </style>
      <Calendar
        tileClassName={({ date, view }) => {
          const isActive = isDateActive(date);
          const hasParticipant = filteredData.some(item => {
            const itemDate = new Date(item.startDate);
            return (
              itemDate.getFullYear() === date.getFullYear() &&
              itemDate.getMonth() === date.getMonth() &&
              itemDate.getDate() === date.getDate() &&
              item.participant
            );
          });
          const isPastDate = date.getTime() < new Date().setHours(0, 0, 0, 0);
          if (view === 'month') {
            const isToday = date.toDateString() === new Date().toDateString();

            const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(
              2,
              '0'
            )}`;

            const hasInstructions = instructionsPayload[formattedDate]?.length > 0;
            console.log(
              'formattedDate:',
              formattedDate,
              'instructionsPayload[formattedDate]:',
              instructionsPayload[formattedDate],
              'hasInstructions:',
              hasInstructions
            );

            if (isActive && hasParticipant && isToday) {
              if (hasInstructions) {
                return 'activeDateDotParticipant';
              }
              return 'activeDateParticipant';
            }

            if (isActive && hasParticipant) {
              if (hasInstructions) {
                return isPastDate ? 'dimmedActiveDateParticipant' : 'activeDateDotParticipant';
              } else {
                return isPastDate ? 'dimmedActiveDateParticipant' : 'activeDateParticipant';
              }
            }

            // Apply activeDate for other active dates
            if (isActive) {
              if (hasInstructions) {
                return isPastDate ? 'dimmedActiveDate' : 'activeDotDate';
              } else {
                return isPastDate ? 'dimmedActiveDate' : 'activeDate';
              }
            }
          }

          return undefined;
        }}
        tileDisabled={({ date, view }) => {
          if (view === 'month') {
            return !isDateActive(date);
          }
          return false;
        }}
        onClickDay={handleDateClick}
      />
    </Dialog>
  );
};

export default RecurringRangeModal;
