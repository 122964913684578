import React, { useState } from 'react';
import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useAppSelector } from 'app/config/store';

const ChatOptions = ({ handleMenuItemClick, message, chatDetails }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const userDetails = useAppSelector(state => state.authentication.account);
  const handleChatOptonsClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleMenuItemClickInternal = action => {
    handleClose();
    handleMenuItemClick(action, message);
  };

  //console.log('chatDetails', message);

  return (
    <div>
      <IconButton edge="end" size="small" aria-label="options" onClick={handleChatOptonsClick}>
        <MoreHorizIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleClose}>
        {chatDetails?.organiser === true && message?.pinned === false && (
          <MenuItem onClick={() => handleMenuItemClickInternal('pin')}>Pin</MenuItem>
        )}
        {chatDetails?.organiser === true && message?.pinned === true && (
          <MenuItem onClick={() => handleMenuItemClickInternal('pin')}>Unpin</MenuItem>
        )}
        {message?.eventGallery?.length === 0 && <MenuItem onClick={() => handleMenuItemClickInternal('copy')}>Copy</MenuItem>}
        {(userDetails?.id === message?.userId || chatDetails?.organiser === true) && (
          <MenuItem onClick={() => handleMenuItemClickInternal('delete')}>Delete</MenuItem>
        )}
        {userDetails?.id !== message?.userId && !message?.reported && (
          <MenuItem onClick={() => handleMenuItemClickInternal('report')}>Report</MenuItem>
        )}
        {userDetails?.id == message?.userId && <MenuItem onClick={() => handleMenuItemClickInternal('edit')}>Edit</MenuItem>}
      </Menu>
    </div>
  );
};

export default ChatOptions;
