import React, { useState, useEffect, useCallback } from 'react';
import { useAppSelector, useAppDispatch } from 'app/config/store';
import FormControl from '@mui/material/FormControl';
import {
  Checkbox,
  FormControlLabel,
  OutlinedInput,
  Typography,
  TextField,
  Dialog,
  useMediaQuery,
  Button,
  Autocomplete,
  useTheme,
} from '@mui/material';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import { fetchCategories } from 'app/shared/reducers/categories';
import { useDropzone } from 'react-dropzone';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import RepeatOutlinedIcon from '@mui/icons-material/RepeatOutlined';
import axios from 'axios';
import dayjs from 'dayjs';
import { fetchLocations } from 'app/shared/reducers/location';
import MapBoxMapView from 'app/components/common/map-box/map-view-mapbox';
import DeleteIcon from '@mui/icons-material/Delete';
import './create-activity-body.scss';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { Carousel } from 'react-responsive-carousel';
import { useLocation } from 'react-router-dom';
import currencyData from 'app/entities/constants/currencies.json';
import { apiEndPoints } from 'app/utils/data/constants/api-end-points';
import StaticGMapWithMarker from 'app/components/common/google-maps/static-g-map-with-marker';
import Chip from '@mui/material/Chip';
import darkTheme from 'app/styles/theme/dark-theme';
import { StyledTextarea } from 'app/components/common/text-area/styled-textare';
import ActivityStartTime from './activity-start-time/activity-start-time';
import ActivityEndTime from './activity-end-time/activity-end-time';
import VideoPlayer from 'app/components/common/video-player/video-player';
import LostPet from './lost-pet/lost-pet';
import { fetchUserGrantedOrganizations } from 'app/shared/reducers/organization';
import { apiKeys } from 'app/utils/data/constants/api-keys';

const CreateActivityBody = ({ formik, setError }: any) => {
  const isDarkMode = useAppSelector(state => state.darkMode);
  const [categories, setCategories] = useState<any>([]);
  const [activityFrequencies, setActivityFrequencies] = useState<[]>([]);
  const [activityFrequencyId, setAvtivityFrequencyId] = useState<any>('');
  const [locations, setLocations] = useState<any>([]);
  const theme = useTheme();
  const isDark = theme.palette.mode === 'dark';
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const valuesArray = Array.from({ length: 88 }, (_, index) => ({ value: index + 13 }));
  const dispatch = useAppDispatch();
  const [mapTouched, setMapTouched] = useState<boolean>(false);
  const [locationFromSearch, setLocationFromSearch] = useState<string>('');
  const [selectedCurrency, setSelectedCurrency] = useState(null);
  const [isCustomOpen, setIsCustomOpen] = useState(false);
  const [isEndDate, setIsEndDate] = useState(false);
  const [requiredDaysValue, setRequiredDaysValue] = useState('1');
  const [endDateMin, setEndDateMin] = useState(new Date());
  const [endDateNormal, setEndDateNormal] = useState(dayjs(new Date()));
  const [endDateMax, setEndDateMax] = useState(dayjs().add(1, 'day'));
  const [endDateMaxCust, setEndDateMaxCust] = useState(new Date());
  const [requiredDaysUnit, setRequiredDaysUnit] = useState('day');
  const [selectedEndDate, setSelectedEndDate] = useState('');
  const [totalImages, setTotalImages] = useState(0);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [files, setFiles] = useState<File[]>([]);
  const [multiMedia, setMultiMedia] = useState<{ id: number; url: string; type: any }[]>(formik.values?.multimedia);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [totalSize, setTotalSize] = useState(0);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [postAsSelectedValue, setPostAsSelectedValue] = useState('');

  const [selectedLocation, setSelectedLocation] = useState<{ lat: number; lng: number }>(null);
  const [latitudeNumber, setlatitude] = useState<number>(null);
  const [longitudeNumber, setlongitude] = useState<number>(null);
  const userGrantedOrganizations = useAppSelector(state => state.organizationReducer.userGrantedOrganizations);

  const [selectedValue, setSelectedValue] = useState('');

  const handleEndDate = () => {
    formik.setFieldValue('customFrequency[0].weeks', 0);
    formik.setFieldValue('customFrequency[0].days', 0);
    formik.setFieldValue('customFrequency[0].months', 0);
    formik.setFieldValue('customFrequency[0].weekDays', []);
    setIsEndDate(true);
  };
  const handleCustomizeClick = () => {
    setRequiredDaysValue('1');
    setRequiredDaysUnit('day');
    setSelectedEndDate('');
    setSelectedDaysOfWeek([]);

    const currentDate = dayjs(new Date());
    const minDate = currentDate.add(1, 'day');
    const maxDate = currentDate.add(1, 'day');
    setEndDateMin(minDate.toDate());
    setEndDateMaxCust(maxDate.toDate());
    const formattedDateString = minDate.toISOString().split('T')[0];
    setSelectedEndDate(formattedDateString);
    const formattedDateForFormik = dayjs(formattedDateString).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
    const selectedTime = dayjs(formik.values.activityEndTime);
    const endDateDatePart = dayjs(formattedDateForFormik).format('YYYY-MM-DD');
    const newActivityEndDate = dayjs(`${endDateDatePart}T${selectedTime.format('HH:mm:ss')}`).toDate();
    formik.setFieldValue('endDate', newActivityEndDate);

    formik.setFieldValue('customFrequency', [
      {
        days: 1,
        weeks: 0,
        months: 0,
        weekDays: [],
      },
    ]);
    setIsCustomOpen(true);
  };
  const handleCustomModal = () => {
    setIsCustomOpen(false);
    formik.setFieldValue('activityFrequencyId', 1);
  };

  const dayAbbreviations = ['S', 'M', 'T', 'W', 'TH', 'F', 'SA'];

  const dayFullNameMap = {
    S: 'Sunday',
    M: 'Monday',
    T: 'Tuesday',
    W: 'Wednesday',
    TH: 'Thursday',
    F: 'Friday',
    SA: 'Saturday',
  };
  const todayAbbreviation = dayAbbreviations[new Date().getDay()];

  const initialSelectedDays = [dayFullNameMap[todayAbbreviation]];
  const [selectedDaysOfWeek, setSelectedDaysOfWeek] = useState(initialSelectedDays);
  useEffect(() => {
    const handleLocationSuccess = position => {
      const latitude = position.coords.latitude;
      const longitude = position.coords.longitude;
      console.log('position', position);
      localStorage.setItem('currentLatitude', JSON.stringify(position.coords.latitude));
      localStorage.setItem('currentLongitude', JSON.stringify(position.coords.longitude));
      setLatitude(latitude);
      setLongitude(longitude);
      localStorage.setItem('latitude', latitude.toString());
      localStorage.setItem('longitude', longitude.toString());

      const payload = { latitude, longitude };

      const handleLocationError = error => {
        console.log('location denied');
        let errorMessage =
          'Location Service message: Geo-location based services will no longer be accessible to you. Please enable location services if you wish to use these features.';
        if (error.code === error.PERMISSION_DENIED) {
          errorMessage =
            'You have denied access to location services. Please enable location services in your browser settings to use geo-location based features.';
        } else if (error.code === error.POSITION_UNAVAILABLE) {
          errorMessage = 'Location information is unavailable. Please check your device settings and try again.';
        } else if (error.code === error.TIMEOUT) {
          errorMessage = 'The request to get your location timed out. Please try again.';
        } else if (error.code === error.UNKNOWN_ERROR) {
          errorMessage = 'An unknown error occurred while trying to access your location.';
        }
        alert(errorMessage);
      };
    };

    if ('geolocation' in navigator) {
      console.log('location get');
      navigator.geolocation.getCurrentPosition(handleLocationSuccess);
    } else {
      console.log('location denied');
      alert(
        'Location Service message: Geo-location based services will no longer be accessible to you. Please enable location services if you wish to use these features.'
      );
    }
  }, []);
  const handleCustomDoneModal = () => {
    setIsCustomOpen(false);
  };
  const dayOrder = ['Saturday', 'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday'];

  const sortDaysOfWeek = days => {
    return days.sort((a, b) => dayOrder.indexOf(a) - dayOrder.indexOf(b));
  };
  const renderRequiredDaysDropdown = useCallback(() => {
    if (requiredDaysUnit === 'day') {
      return (
        <select
          id="requiredDays"
          value={requiredDaysValue}
          onChange={e => {
            const selectedDay = e.target.value;
            setRequiredDaysValue(selectedDay);
            const numberOfDays = parseInt(selectedDay, 10);
            const currentDate = new Date(formik.values.startDate);
            currentDate.setDate(currentDate.getDate() + numberOfDays);
            const newEndDateNormal = dayjs(formik.values.startDate).add(numberOfDays, 'day').toDate();
            setEndDateMin(newEndDateNormal);
            setSelectedEndDate(newEndDateNormal.toISOString().split('T')[0]);
            const formattedDateString = newEndDateNormal.toISOString().split('T')[0];
            setSelectedEndDate(formattedDateString);
            const endDateInput = document.getElementById('endDate') as HTMLInputElement;
            endDateInput.value = formattedDateString;
            const formattedDateForFormik = dayjs(formattedDateString).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
            const selectedTime = dayjs(formik.values.activityEndTime);
            const endDateDatePart = dayjs(formattedDateForFormik).format('YYYY-MM-DD');
            const newActivityEndDate = dayjs(`${endDateDatePart}T${selectedTime.format('HH:mm:ss')}`).toDate();
            formik.setFieldValue('endDate', newActivityEndDate);
            const currentEndDate = new Date(formik.values.startDate);
            currentEndDate.setDate(currentEndDate.getDate() + numberOfDays * 30);
            setEndDateMaxCust(currentEndDate);
            formik.setFieldValue('customFrequency[0].days', parseInt(selectedDay, 10));
            formik.setFieldValue('customFrequency[0].weeks', 0);
            formik.setFieldValue('customFrequency[0].months', 0);
            formik.setFieldValue('customFrequency[0].weekDays', []);
          }}
          style={{
            backgroundColor: '#F6F7F8',
            borderRadius: '32px',
            border: 'none',
            color: '#676E7B',
            padding: '2px',
          }}
        >
          {Array.from({ length: 365 }, (_, i) => i + 1).map(day => (
            <option key={day} value={day}>
              {day}
            </option>
          ))}
        </select>
      );
    } else if (requiredDaysUnit === 'week') {
      return (
        <select
          id="requiredDays"
          value={requiredDaysValue}
          onChange={e => {
            const selectedWeek = e.target.value;
            setRequiredDaysValue(selectedWeek);
            const selectedWeekdays = selectedDaysOfWeek;
            const numberOfDays = parseInt(selectedWeek, 10);
            let selectedWeekdayCount = 0;
            let currentDate = new Date(formik.values.startDate);
            let addedDays = 0;
            let currentWeek = 1;
            while (selectedWeekdayCount < numberOfDays) {
              currentDate.setDate(currentDate.getDate() + selectedWeekdays?.length);
              const currentDay = currentDate.toLocaleDateString('en-US', { weekday: 'long' });

              if (selectedWeekdays.includes(currentDay)) {
                currentWeek++;
                selectedWeekdayCount++;
              }

              addedDays++;

              if (currentWeek === numberOfDays) {
                break;
              }
            }

            const newCurrentdate = dayjs(currentDate).toDate();
            console.log('enetr into weeks', newCurrentdate);
            setEndDateMin(newCurrentdate);
            const lastWeekDate = new Date(currentDate);
            lastWeekDate.setDate(lastWeekDate.getDate() + numberOfDays * (11 * 7));
            setEndDateMaxCust(lastWeekDate);
            setSelectedEndDate(lastWeekDate.toISOString().split('T')[0]);
            const formattedDateString = lastWeekDate.toISOString().split('T')[0];
            setSelectedEndDate(formattedDateString);
            const endDateInput = document.getElementById('endDate') as HTMLInputElement;
            endDateInput.value = formattedDateString;
            const formattedDateForFormik = dayjs(formattedDateString).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
            const selectedTime = dayjs(formik.values.activityEndTime);
            const endDateDatePart = dayjs(formattedDateForFormik).format('YYYY-MM-DD');
            const newActivityEndDate = dayjs(`${endDateDatePart}T${selectedTime.format('HH:mm:ss')}`).toDate();
            formik.setFieldValue('endDate', newActivityEndDate);
            formik.setFieldValue('customFrequency[0].weeks', parseInt(selectedWeek, 10));
            formik.setFieldValue('customFrequency[0].days', 0);
            formik.setFieldValue('customFrequency[0].months', 0);
            formik.setFieldValue('customFrequency[0].weekDays', selectedDaysOfWeek);
          }}
          style={{
            backgroundColor: '#F6F7F8',
            borderRadius: '32px',
            border: 'none',
            color: '#676E7B',
            padding: '2px',
          }}
        >
          {Array.from({ length: 53 }, (_, i) => i + 1).map(week => (
            <option key={week} value={week}>
              {week}
            </option>
          ))}
        </select>
      );
    } else if (requiredDaysUnit === 'month') {
      return (
        <select
          id="requiredDays"
          value={requiredDaysValue}
          onChange={e => {
            const selectedMonth = e.target.value;
            const numberOfMonths = parseInt(selectedMonth, 10);
            const currentDate = new Date(formik.values.startDate);
            function addMonths(date, months) {
              const newDate = new Date(date);
              newDate.setMonth(date.getMonth() + months);
              return newDate;
            }
            const endDate = addMonths(currentDate, numberOfMonths);
            const newCurrentdate = dayjs(currentDate).toDate();
            setEndDateMin(newCurrentdate);
            const endDateMaxCust = addMonths(endDate, 3);
            setEndDateMaxCust(endDateMaxCust);
            setSelectedEndDate(endDateMaxCust.toISOString().split('T')[0]);
            const formattedDateString = endDateMaxCust.toISOString().split('T')[0];
            setSelectedEndDate(formattedDateString);
            const endDateInput = document.getElementById('endDate') as HTMLInputElement;
            endDateInput.value = formattedDateString;
            const formattedDateForFormik = dayjs(formattedDateString).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
            const selectedTime = dayjs(formik.values.activityEndTime);
            const endDateDatePart = dayjs(formattedDateForFormik).format('YYYY-MM-DD');
            const newActivityEndDate = dayjs(`${endDateDatePart}T${selectedTime.format('HH:mm:ss')}`).toDate();
            formik.setFieldValue('endDate', newActivityEndDate);
            formik.setFieldValue('customFrequency[0].months', parseInt(selectedMonth, 10));
            formik.setFieldValue('customFrequency[0].days', 0);
            formik.setFieldValue('customFrequency[0].weeks', 0);
            formik.setFieldValue('customFrequency[0].weekDays', []);
            setRequiredDaysValue(selectedMonth);
          }}
          style={{
            backgroundColor: '#F6F7F8',
            borderRadius: '32px',
            border: 'none',
            color: '#676E7B',
            padding: '2px',
          }}
        >
          {Array.from({ length: 12 }, (_, i) => i + 1).map(month => (
            <option key={month} value={month}>
              {month}
            </option>
          ))}
        </select>
      );
    }
  }, [requiredDaysUnit, requiredDaysValue, formik.values.startDate, selectedDaysOfWeek]);

  const handleRequiredDaysUnitChange = e => {
    const selectedUnit = e.target.value;
    setRequiredDaysUnit(selectedUnit);

    if (selectedUnit === 'day') {
      const numberOfDays = parseInt(requiredDaysValue, 10);
      const currentDate = new Date(formik.values.startDate);
      currentDate.setDate(currentDate.getDate() + numberOfDays);
      const newCurrentdate = dayjs(currentDate).toDate();
      setEndDateMin(newCurrentdate);
      formik.setFieldValue('customFrequency[0].weeks', 0);
      formik.setFieldValue('customFrequency[0].days', parseInt(requiredDaysValue, 10));
      formik.setFieldValue('customFrequency[0].months', 0);
      formik.setFieldValue('customFrequency[0].weekDays', []);
    } else if (selectedUnit === 'week') {
      const todayAbbreviation = dayAbbreviations[new Date().getDay()];
      const initialSelectedDays = [dayFullNameMap[todayAbbreviation]];
      const updatedSelectedDays = sortDaysOfWeek(initialSelectedDays);
      setSelectedDaysOfWeek(updatedSelectedDays);
      const selectedWeekdays = selectedDaysOfWeek;
      const numberOfWeeks = parseInt(requiredDaysValue, 10);
      const currentDate = new Date(formik.values.startDate);
      currentDate.setDate(currentDate.getDate() + numberOfWeeks * 7);
      const newCurrentdate = dayjs(currentDate).toDate();
      setEndDateMin(newCurrentdate);
      formik.setFieldValue('customFrequency[0].weeks', numberOfWeeks);
      formik.setFieldValue('customFrequency[0].days', 0);
      formik.setFieldValue('customFrequency[0].months', 0);
      formik.setFieldValue('customFrequency[0].weekDays', updatedSelectedDays);
    } else if (selectedUnit === 'month') {
      const numberOfMonths = parseInt(requiredDaysValue, 10);
      const currentDate = new Date(formik.values.startDate);
      const endDate = new Date(currentDate.setMonth(currentDate.getMonth() + numberOfMonths));
      const newCurrentdate = dayjs(endDate).toDate();
      setEndDateMin(newCurrentdate);
      formik.setFieldValue('customFrequency[0].weeks', 0);
      formik.setFieldValue('customFrequency[0].days', 0);
      formik.setFieldValue('customFrequency[0].months', numberOfMonths);
      formik.setFieldValue('customFrequency[0].weekDays', []);
    }
  };

  const handleEndDateChange = e => {
    const selectedDate = e.target.valueAsDate;
    const formattedDateString = selectedDate.toISOString().split('T')[0];
    setSelectedEndDate(formattedDateString);
    const endDateInput = document.getElementById('endDate') as HTMLInputElement;
    endDateInput.value = formattedDateString;
    const formattedDateForFormik = dayjs(formattedDateString).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
    const selectedTime = dayjs(formik.values.activityEndTime);
    const endDateDatePart = dayjs(formattedDateForFormik).format('YYYY-MM-DD');
    const newActivityEndDate = dayjs(`${endDateDatePart}T${selectedTime.format('HH:mm:ss')}`).toDate();
    formik.setFieldValue('endDate', newActivityEndDate);
  };

  const handleDayOfWeekToggle = day => {
    let updatedSelectedDays;
    if (selectedDaysOfWeek.includes(day)) {
      updatedSelectedDays = selectedDaysOfWeek.filter(selectedDay => selectedDay !== day);
    } else {
      updatedSelectedDays = [...selectedDaysOfWeek, day];
    }
    updatedSelectedDays = sortDaysOfWeek(updatedSelectedDays);
    setSelectedDaysOfWeek(updatedSelectedDays);
    formik.setFieldValue('customFrequency[0].weekDays', updatedSelectedDays);

    const numberOfDays = parseInt(requiredDaysValue, 10);
    let selectedWeekdayCount = 0;
    let currentDate = new Date(formik.values.startDate);
    let addedDays = 0;

    while (selectedWeekdayCount < numberOfDays) {
      currentDate.setDate(currentDate.getDate() + 1);
      const currentDay = currentDate.toLocaleDateString('en-US', { weekday: 'long' });
      if (updatedSelectedDays.includes(currentDay)) {
        selectedWeekdayCount++;
      }

      addedDays++;
      if (addedDays > 365) {
        console.error('Loop exceeded 365 days, breaking out to avoid infinite loop.');
        break;
      }
    }

    const newCurrentDate = dayjs(currentDate).toDate();
    setEndDateMin(newCurrentDate);
  };

  const handleCurrencyChange = (event, newValue) => {
    setSelectedCurrency(newValue);
    formik.setFieldValue('currency', newValue ? newValue.code : '');
  };
  const acceptingFiles = {
    'image/jpeg': [],
    'image/png': [],
    'image/jpg': [],
    'video/mp4': [],
    'video/webm': [],
    'video/ogg': [],
  };
  const onDrop = useCallback(
    acceptedFiles => {
      const maxSizeImage = 5 * 1024 * 1024; // 5MB
      const maxSizeVideo = 20 * 1024 * 1024; // 20MB
      const totalSize = acceptedFiles.reduce((acc, file) => acc + file.size, 0);
      const newTotalImages = totalImages + acceptedFiles.length;

      setError(false);

      if (newTotalImages > 10) {
        setErrorMessage('You can upload a maximum of 10 files.');
        return;
      }

      if (totalSize > maxSizeVideo && acceptedFiles.some(file => file.type.includes('video'))) {
        setErrorMessage('Maximum total size of video uploads exceeded (20MB)');
        return;
      }

      if (totalSize > maxSizeImage && acceptedFiles.some(file => file.type.includes('image'))) {
        setErrorMessage('You have exceeded the maximum allowed size for image uploads (5MB) and videos (20MB).');
        return;
      }

      const invalidFiles = [];
      acceptedFiles.forEach(file => {
        if (file.type.includes('video') && file.size > maxSizeVideo) {
          setErrorMessage(`File '${file.name}' exceeds maximum size limit for videos (20MB)`);
          invalidFiles.push(file);
        } else if (file.type.includes('image') && file.size > maxSizeImage) {
          setErrorMessage(`File '${file.name}' exceeds maximum size limit for images (5MB)`);
          invalidFiles.push(file);
        }

        if (file.type.includes('image')) {
          const reader = new FileReader();
          reader.onload = e => {
            const img = new Image();
            img.onload = () => {
              const ratio = (img.width / img.height) * 100;
              console.log(ratio, 'RATIO=========');
              if (ratio < 30) {
                setErrorMessage(`Image '${file.name}' has an invalid aspect ratio.`);
                invalidFiles.push(file);
                setError(true);
              }
            };
            if (typeof e.target.result === 'string') {
              img.src = e.target.result;
            }
          };
          reader.readAsDataURL(file);
        }
      });

      // Since reading files is asynchronous, ensure to handle state updates appropriately.
      setTimeout(() => {
        const validFiles = acceptedFiles.filter(file => !invalidFiles.includes(file));
        setFiles(prevFiles => [...validFiles]);

        setTotalImages(newTotalImages);
        setErrorMessage('');
      }, 0);
    },
    [files]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: acceptingFiles,
    multiple: true,
  });

  const currencyList = Object.keys(currencyData).map(currencyCode => ({
    code: currencyCode,
    label: `${currencyData[currencyCode].name} - ${currencyData[currencyCode].symbol}`,
  }));
  const [latitudeNear, setLatitude] = useState<any>('');
  const [longitudeNear, setLongitude] = useState<any>('');
  const handleOpenModal = () => {
    setIsModalOpen(true);
    setMapTouched(true);
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition(
        function (position) {
          const latitude = position.coords.latitude;
          const longitude = position.coords.longitude;
          setLatitude(latitude);
          setLongitude(longitude);
        },
        function (error) {
          if (error.code === error.PERMISSION_DENIED) {
            alert('Location access denied. Please enable location services.');
          } else {
            alert('Error getting location: ' + error.message);
          }
        }
      );
    } else {
      alert('Geolocation is not supported in this browser.');
    }
  };
  const handleCloseModal = async () => {
    setIsModalOpen(false);

    if (formik.values.latitude === '' || (formik.values.longitude === '' && mapTouched)) {
      try {
        const latitude = Number(latitudeNear);
        const longitude = Number(longitudeNear);

        const response = await fetch(
          `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latitude},${longitude}&key=${apiKeys.GMAP_API_Key}`
        );

        if (response.ok) {
          const data = await response.json();

          if (data.results && data.results.length > 0) {
            console.log('map results', data);

            const compoundCode = data.plus_code.compound_code;

            const locationParts = compoundCode
              .split(' ')
              .slice(1)
              .join(' ')
              .split(',')
              .map(part => part.trim());
            const country = locationParts.pop() || '';
            const state = locationParts.pop() || '';
            const city = locationParts.pop() || '';
            console.log('Country:', country);
            console.log('State:', state);
            console.log('City:', city);
            formik.setFieldValue('latitude', latitude);
            formik.setFieldValue('longitude', longitude);
            formik.setFieldValue('place', data?.plus_code?.compound_code);
            formik.setFieldValue('city', city);
            formik.setFieldValue('state', state);
            formik.setFieldValue('country', country);
          } else {
            console.error('No address found for the provided coordinates.');
          }
        } else {
          console.error('Geocoding API error:', response.statusText);
        }
      } catch (error) {
        console.error('Error fetching geocoding data:', error);
      }
    }
  };

  //callback function to handle selected location
  const handleSelectLocation = location => {
    const { lat, lng } = location;

    formik.setFieldValue('latitude', lat);
    formik.setFieldValue('longitude', lng);
    setSelectedLocation(location);
    setlatitude(lat);
    setlongitude(lng);
  };
  useEffect(() => {
    if (formik.values.latitude > 0) {
      formik.setFieldValue('latitude', formik.values.latitude);
    }
  }, [formik.values.latitude]);

  useEffect(() => {
    const now = dayjs();
    const cutoffTime = dayjs().hour(23).minute(40);
    if (formik.values.startDate === null || formik.values.startDate === '') {
      if (now.isAfter(cutoffTime)) {
        formik.setFieldValue('startDate', dayjs().add(1, 'day').startOf('day'));
      } else {
        const roundedMinutes = Math.ceil(now.minute() / 15) * 15;
        const roundedStartDate = now.minute(roundedMinutes).second(0);
        formik.setFieldValue('startDate', roundedStartDate);
      }
      formik.setFieldValue('endDate', null);
    }

    const fetchData = async () => {
      try {
        const response = await dispatch(fetchCategories());
        setCategories(response.payload);
      } catch (error) {
        console.log(error);
      }
    };

    fetchData();
  }, []);
  // useEffect(() => {

  //   if(formik.values?.draft){

  //   }
  // }, [formik.values.draft]);
  useEffect(() => {
    if (formik.values?.endDate != null && formik.values?.activityFrequencyId != 1 && formik.values?.activityFrequencyId != '') {
      formik.setFieldValue('endDate', formik.values?.endDate);
      setIsEndDate(true);
    } else {
      setIsEndDate(false);
    }
  }, [formik.values?.endDate, formik.values?.activityFrequencyId]);

  useEffect(() => {
    if (formik.values?.categoryID === 19) {
      const selectedDateCurrent = dayjs(new Date());
      formik.setFieldValue('startDate', selectedDateCurrent);
      formik.setFieldValue('activityStartTime', selectedDateCurrent);
      const endOfDay = selectedDateCurrent.hour(23).minute(58).second(19);
      formik.setFieldValue('activityEndTime', endOfDay);
    }
  }, [formik.values?.categoryID]);

  useEffect(() => {
    if (files !== undefined) {
      const sendFiles = async () => {
        try {
          for (const file of files) {
            const formData = new FormData();
            formData.append('file', file);
            const uploadEndpoint = file.type.includes('video') ? apiEndPoints.videoUpload : apiEndPoints?.imageUpload;
            const response = await axios.post<any>(uploadEndpoint, formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            });

            if (response.status === 201) {
              setMultiMedia(state => {
                const responseData = response?.data;
                if (!responseData) {
                  return state || [];
                }

                const newState = state ? state.filter(item => item.url !== responseData.url) : [];
                newState.push(responseData);
                return newState;
              });
            }
          }
        } catch (error) {
          console.log(error);
        }
      };
      sendFiles();
    }
  }, [files]);

  useEffect(() => {
    formik.setFieldValue('multimedia', multiMedia);
  }, [multiMedia]);

  const { pathname } = useLocation();
  if (pathname !== '/create-activity') {
    useEffect(() => {
      setMultiMedia(formik.values?.multimedia);
    }, [formik.values?.multimedia]);
  }
  useEffect(() => {
    if (activityFrequencyId) {
      formik.setFieldValue('activityFrequencyId', activityFrequencyId);
      setAvtivityFrequencyId(activityFrequencyId);
    }
  }, [activityFrequencies, activityFrequencyId]);

  useEffect(() => {
    if (formik.values?.categoryID === 19) {
      formik.setFieldValue('activityFrequencyId', 1);
      formik.setFieldValue('endDate', null);
    }
  }, [formik.values?.categoryID]);

  const handleSelectFrequencies = (event: SelectChangeEvent) => {
    setAvtivityFrequencyId(event.target.value);
    formik.setFieldValue('activityFrequencyId', event.target.value);
  };

  useEffect(() => {
    const fetchSubCategory = async () => {
      try {
        const response = await axios.get<any>(`api/activity-frequencies`);
        setActivityFrequencies(response.data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchSubCategory();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await dispatch(fetchLocations());
        setLocations(response.payload);
      } catch (error) {
        console.log(error);
      }
    };

    const getUserGrantedOrganizations = async () => {
      try {
        await dispatch(fetchUserGrantedOrganizations());
      } catch (error) {
        console.log(error);
      }
    };

    getUserGrantedOrganizations();
    fetchData();
  }, [dispatch]);

  const postAsData = ['Org1', 'Org2'];

  useEffect(() => {
    if (!formik?.values?.activityStartTime) {
      const currentTime = new Date();
      const roundedMinutes = Math.ceil(currentTime.getMinutes() / 15) * 15;
      const roundedTime = new Date(currentTime);
      roundedTime.setMinutes(roundedMinutes);
      formik.setFieldValue('activityStartTime', roundedTime);
    }
    if (!formik?.values?.activityEndTime) {
      const currentTime = new Date();
      const roundedMinutes = Math.ceil(currentTime.getMinutes() / 60) * 60;
      const roundedTime = new Date(currentTime);
      roundedTime.setMinutes(roundedMinutes);
      formik.setFieldValue('activityEndTime', roundedTime);
    }
  }, []);
  useEffect(() => {
    if (formik?.values?.customFrequency === null) {
      formik.setFieldValue('customFrequency', []);
    }
    if (formik?.values?.customFrequency?.length !== 0 && formik?.values?.customFrequency != null) {
      const customFrequency = formik.values.customFrequency[0];
      let unit = null;
      let value = null;
      if (customFrequency.days > 0) {
        unit = 'day';
        value = customFrequency.days;
      } else if (customFrequency.weeks > 0) {
        unit = 'week';
        value = customFrequency.weeks;
      } else if (customFrequency.months > 0) {
        unit = 'month';
        value = customFrequency.months;
      }
      if (unit && value && formik?.values?.endDate) {
        setRequiredDaysUnit(unit);
        setRequiredDaysValue(value.toString());
        const formattedDateString = dayjs(formik.values.endDate).format('YYYY-MM-DD');
        // setSelectedEndDate(formattedDateString);
      }
    }

    if (formik?.values?.endDate) {
      const selectedTime = dayjs(formik.values.activityEndTime);
      const endDateDatePart = dayjs(formik.values.endDate).format('YYYY-MM-DD');
      const newActivityEndDate = dayjs(`${endDateDatePart}T${selectedTime.format('HH:mm:ss')}`).toDate();
      formik.setFieldValue('endDate', newActivityEndDate);
    }
  }, [formik?.values?.customFrequency]);

  const handleImageClear = id => {
    if (formik.values.volunteerParticipant > 1 || formik.values.child) {
      return;
    }
    const newLocalMultiMedia = [...multiMedia];
    const deletedImageIndex = newLocalMultiMedia.findIndex(item => item.id === id);
    if (deletedImageIndex !== -1) {
      newLocalMultiMedia.splice(deletedImageIndex, 1);
      files.splice(deletedImageIndex, 1);
      const newMultiMedia = multiMedia.filter(media => media.id !== id);
      setMultiMedia(newMultiMedia);
      formik.setFieldValue('multimedia', newMultiMedia);
      setTotalImages(newMultiMedia.length);
      setErrorMessage('');
      if (newMultiMedia.length > 0) {
        setSelectedImageIndex(0);
      }
    }
  };

  const handleLocationFromSearchBox = (location: string) => {
    setLocationFromSearch(location);
    formik.setFieldValue('place', location);
  };
  const [inputValue, setInputValue] = useState(formik.values.header);
  const debouncedSetFieldValue = useCallback(
    (field, value) => {
      let timeoutId;
      const debouncedUpdate = () => {
        clearTimeout(timeoutId);
        formik.setFieldValue(field, value);
      };
      timeoutId = setTimeout(debouncedUpdate, 10);
    },
    [formik]
  );

  useEffect(() => {
    setInputValue(formik.values.header);
    if (formik.values.multimedia === null) {
      formik.setFieldValue('multimedia', []);
    }
  }, [formik.values.header, formik.values.multimedia]);
  const [detailsValue, setDetailsValue] = useState(formik.values.details);

  useEffect(() => {
    setDetailsValue(formik.values.details);
  }, [formik.values.details]);

  useEffect(() => {
    if (formik.values?.amountToRaise === null) {
      formik.setFieldValue('amountToRaise', '');
    }
    if (formik.values?.currency === null) {
      formik.setFieldValue('currency', '');
    }
    formik.setFieldValue('includeMicroFunding', formik.values.includeMicroFunding);
  }, [formik.values.includeMicroFunding, formik.values.currency, formik.values.amountToRaise]);
  useEffect(() => {
    if (formik.values.currency === null) {
      formik.setFieldValue('currency', '');
    } else {
      formik.setFieldValue('currency', formik.values.currency);
    }
  }, [formik.values.currency]);

  useEffect(() => {
    if (
      formik.values.activityEndTime != null &&
      formik.values.activityEndTime != '' &&
      formik.values.activityFrequencyId != 1 &&
      formik.values.endDate != null
    ) {
      const selectedTime = dayjs(formik.values.activityEndTime);
      const endDateDatePart = dayjs(formik.values.endDate).format('YYYY-MM-DD');
      const newActivityEndDate = dayjs(`${endDateDatePart}T${selectedTime.format('HH:mm:ss')}`).toDate();
      formik.setFieldValue('endDate', newActivityEndDate);
    }

    if (formik.values.activityFrequencyId === 2) {
      const newEndDateNormal = dayjs(formik.values.startDate).add(1, 'day');
      const endDateMax = dayjs(formik.values.startDate).add(30, 'day');
      setEndDateMax(endDateMax);
      formik.setFieldValue('urgent', false);
    }
    if (formik.values.activityFrequencyId === 3) {
      const startDate = dayjs(formik.values.startDate);
      const newEndDateNormal = startDate.add(1, 'week').day(startDate.day());
      const endDateMax = startDate.add(12, 'week');
      setEndDateMax(endDateMax);
      formik.setFieldValue('urgent', false);
    }
    if (formik.values.activityFrequencyId === 4) {
      const startDate = dayjs(formik.values.startDate);
      const newEndDateNormal = startDate.add(1, 'month').date(startDate.date());
      const endDateMax = startDate.add(3, 'month');
      setEndDateMax(endDateMax);
      formik.setFieldValue('urgent', false);
    }

    if (formik.values.activityFrequencyId === null) {
      formik.setFieldValue('activityFrequencyId', '');
    }

    if (formik.values.activityFrequencyId !== 1) {
      formik.setFieldValue('urgent', false);
    }
  }, [formik.values.activityEndTime, formik.values.activityFrequencyId, formik.values.startDate]);

  const config = {
    file: {
      attributes: {
        controlsList: 'nodownload',
        disablePictureInPicture: true,
        controls: 'nodownload',
      },
    },
  };

  const isCreateActivity = location.pathname === '/create-activity';

  useEffect(() => {
    if (formik.values.subCategoryID !== 72 && formik.values.lostPet === null) {
      formik.setFieldValue('lostPet', {});
    }
  }, [formik.values.subCategoryID, formik.values.lostPet]);

  const [errorRemote, setErrorRemote] = useState('');
  const [urlState, setUrlState] = useState('');

  const handleUrlChange = event => {
    const value = event.target.value;
    const lowerCaseValue = value.toLowerCase();
    const isValid = lowerCaseValue.startsWith('http://') || lowerCaseValue.startsWith('https://');

    setErrorRemote(isValid ? '' : 'URL must start with http:// or https://');
    setUrlState(value);
    formik.setFieldValue('onlineUrl', value);
    formik.handleChange(event);
  };

  return (
    <Grid container direction={'column'} gap={2}>
      <style>
        {`.css-p827ef-MuiInputBase-root-MuiOutlinedInput-root{
          border:none;
        }
        `}
        {`.css-1c3s6js-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled{
            border:none;
        }`}
        {`.css-18h1urx:focus {
          border: 2px solid #ffc107;
          outline: none; 
        }`}
        {`.css-pvsqx7:focus{
          border: 2px solid #ffc107;
          outline: none; 
          }`}
        {`.css-cz6svd-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled {
            border:none
            }`}
        {`.css-13rkts0{
          border: 1px solid grey;
          
          }`}
        {`.css-1c7rdip-MuiInputBase-root-MuiOutlinedInput-root.Mui-disabled{
          border:none;}`}
        {`.css-1p7411r{
            color: lightgray;

          }`}
      </style>
      {/* Activity header */}
      <FormControl
        fullWidth
        sx={{ mt: 2, backgroundColor: 'transperant', color: 'secondary.main', borderRadius: '15px', marginBottom: '10px' }}
        variant="outlined"
      >
        <OutlinedInput
          name="header"
          autoComplete="off"
          onChange={event => setInputValue(debouncedSetFieldValue('header', event.target.value))}
          onBlur={() => {
            formik.setFieldTouched('header');
            formik.validateField('header');
          }}
          onFocus={() => {
            formik.setFieldTouched('header');
            formik.validateField('header');
          }}
          value={inputValue}
          disabled={formik.values.volunteerParticipant > 1 || formik.values.child}
          spellCheck
          sx={{
            height: '45px',
            fontSize: '.75rem',
            color: formik.values.volunteerParticipant >= 2 || formik.values.child ? 'secondary.contrastText' : 'secondary.main',
            border: '1px solid #BFBFBF',
            '& .MuiOutlinedInput-input::placeholder': {
              color: isDarkMode.darkMode ? '#dddddd' : '#676E7B',
            },
            '& .MuiOutlinedInput-root': {
              background: 'secondary.main',
              borderRadius: '15px',
              border: 'none',
            },
            '&:focus-within,&:hover': {
              border: 'none',
            },
            '& fieldset': {
              borderColor: 'transparent',
            },
          }}
          size="small"
          type={'text'}
          placeholder="Enter the title*"
        />
        <Typography variant="caption" sx={{ color: 'error.main' }}>
          {formik.touched.header && formik.errors.header ? formik.errors.header.toString() : ''}
        </Typography>
      </FormControl>

      <div className="col-12">
        <StyledTextarea
          value={detailsValue}
          disabled={formik.values.volunteerParticipant > 1 || formik.values.child}
          sx={{
            fontSize: '.75rem',
            color: formik.values.volunteerParticipant > 1 || formik.values.child ? '#676E7B' : 'secondary.main',
            padding: '15px 15px 15px 15px',
            '::placeholder': {
              color: isDarkMode.darkMode ? '#a6a6a6	' : '#dadada',
              fontSize: '.75rem',
            },
          }}
          onChange={e => {
            const typedText = e.target.value;
            setDetailsValue(debouncedSetFieldValue('details', typedText));
            if (typedText === '<p><br></p>') {
              formik.setFieldError('details', 'Activity details is required');
            } else if (typedText.length > 0) {
              formik.setFieldError('details', '');
            }
          }}
          onPaste={e => {
            const pastedText = e.clipboardData.getData('text/plain');
            formik.setFieldValue('details', pastedText);
            if (pastedText === '<p><br></p>') {
              formik.setFieldError('details', 'Activity details is required');
            } else if (pastedText.length > 0) {
              formik.setFieldError('details', '');
            }
          }}
          placeholder="Details of your activity *"
        />

        {formik.errors.details != null && (
          <Typography variant="caption" sx={{ color: 'error.main', marginTop: '-18px' }}>
            {formik.touched.details && formik.errors.details ? formik.errors.details.toString() : ''}
          </Typography>
        )}
      </div>

      {/* Activity body */}
      {/* Age limit */}
      {formik.values.categoryID === 19 && formik.values.subCategoryID !== 72 && (
        <>
          <Grid item xs={12} md>
            <FormControl component="fieldset" fullWidth>
              <FormControlLabel
                sx={{ color: 'secondary.main', fontSize: '14px' }}
                control={
                  <Checkbox
                    checked={formik.values.makeThisPrivateActivity === true}
                    name="makeThisPrivateActivity"
                    onChange={event => formik.setFieldValue('makeThisPrivateActivity', event.target.checked)}
                    disableRipple
                    sx={{
                      color: 'transparent',
                      '&.Mui-checked': { color: 'primary.light' },
                      '& .MuiSvgIcon-root': {
                        fontSize: 23,
                        borderRadius: '25%',
                        border: '1px solid #BFBFBF',
                        padding: '3px',
                        marginLeft: '5px',
                      },
                    }}
                  />
                }
                label={
                  <Typography
                    sx={{
                      color: theme.textColor.mainHeading,
                      fontSize: theme.p1.semiBold.fontSize,
                      fontWeight: theme.p1.semiBold.fontWeight,
                    }}
                  >
                    By Invitation Only (paid add-on)
                  </Typography>
                }
                labelPlacement="end"
                disabled={formik.values.volunteerParticipant > 1 || formik.values.child}
              />
            </FormControl>
          </Grid>
        </>
      )}
      {formik.values.categoryID !== 19 && (
        <>
          <Grid container direction={'row'} gap={{ xs: 2, md: 3 }} alignItems={'center'} justifyContent={'space-between'} sx={{ mt: 1 }}>
            <Grid item xs={12} md>
              <FormControl
                fullWidth
                sx={{
                  backgroundColor: 'secondary.dark',
                  color: 'secondary.main',
                  borderRadius: '15px',
                }}
              >
                <Select
                  size="small"
                  displayEmpty
                  name="ageLimit"
                  fullWidth
                  disabled={formik.values.volunteerParticipant > 1 || formik.values.child}
                  startAdornment={
                    !formik.values.ageLimit ? (
                      <div style={{ display: 'flex', alignItems: 'center', width: '-webkit-fill-available' }}>
                        <span style={{ fontSize: '.75rem', color: isDarkMode.darkMode ? '#a6a6a6' : '#dadada' }}>Select age limit</span>
                      </div>
                    ) : null
                  }
                  placeholder="Select Age Limit"
                  labelId="age-limit"
                  id="ageLimit"
                  value={formik.values.ageLimit ? formik.values.ageLimit : ''}
                  onChange={formik.handleChange}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        overflowY: 'scroll',
                      },
                    },
                    MenuListProps: {
                      style: {
                        maxHeight: '200px',
                      },
                    },
                  }}
                  sx={{
                    border: 'none',
                    fontSize: '.75rem',
                    '&.MuiMenu-paper': {
                      backgroundColor: '#FF5500',
                      borderRadius: '15px',
                      border: 'none',
                    },
                    '&.MuiOutlinedInput-root': {
                      background: 'secondary.main',
                      borderRadius: '15px',
                      height: 45,
                      border: 'none',
                      '& fieldset': {
                        border: '1px solid #BFBFBF',
                      },
                    },
                  }}
                >
                  {valuesArray?.map((item: any) => (
                    <MenuItem value={item.value} key={item.value} sx={{ fontSize: '.75rem' }}>
                      {`${item.value}` + '+'}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            {/* Private Activity */}
            <Grid item xs={12} md>
              <FormControl component="fieldset" fullWidth>
                <FormControlLabel
                  sx={{ color: 'secondary.main', fontSize: '14px' }}
                  control={
                    <Checkbox
                      checked={formik.values.makeThisPrivateActivity === true}
                      name="makeThisPrivateActivity"
                      onChange={event => formik.setFieldValue('makeThisPrivateActivity', event.target.checked)}
                      disableRipple
                      sx={{
                        color: 'transparent',
                        '&.Mui-checked': { color: 'primary.light' },
                        '& .MuiSvgIcon-root': {
                          fontSize: 23,
                          borderRadius: '25%',
                          border: '1px solid #BFBFBF',
                          padding: '3px',
                          marginLeft: '5px',
                        },
                      }}
                    />
                  }
                  label={
                    <Typography
                      sx={{
                        color: theme.textColor.mainHeading,
                        fontSize: theme.p1.semiBold.fontSize,
                        fontWeight: theme.p1.semiBold.fontWeight,
                      }}
                    >
                      By Invitation Only (paid add-on)
                    </Typography>
                  }
                  labelPlacement="end"
                  disabled={formik.values.volunteerParticipant > 1 || formik.values.child}
                />
              </FormControl>
            </Grid>
          </Grid>
          <Grid container direction={'row'} gap={{ xs: 2, md: 1 }} alignItems={'center'} justifyContent={'space-between'}>
            {/* Micro funding */}
          </Grid>
          <Grid container direction={'row'} gap={{ xs: 2, md: 1 }} alignItems={'center'} justifyContent={'space-between'}>
            {formik.values.includeMicroFunding && (
              <Grid
                item
                container
                direction={'row'}
                xs={12}
                md={12}
                justifyContent={'space-between'}
                alignItems={'center'}
                gap={1}
                sx={{ mb: 1 }}
              >
                <Grid item xs={12} md={6}>
                  <FormControl fullWidth sx={{ backgroundColor: 'transperant', color: 'secondary.main', borderRadius: '15px' }}>
                    <Autocomplete
                      disablePortal
                      value={
                        formik.values.currency ? currencyList.find(currency => currency.code === formik.values.currency) || null : null
                      }
                      id="currency-select"
                      options={currencyList}
                      size="small"
                      onChange={handleCurrencyChange}
                      fullWidth
                      renderInput={params => (
                        <TextField
                          sx={{ height: 45, color: 'secondary.main' }}
                          {...params}
                          placeholder="Select Currency"
                          disabled={formik.values.volunteerParticipant > 1 || formik.values.child}
                        />
                      )}
                    />
                  </FormControl>
                  {formik.values.includeMicroFunding && formik.touched.currency && formik.values.currency === '' ? (
                    <Typography variant="caption" sx={{ color: 'error.main' }}>
                      currency is required
                    </Typography>
                  ) : null}
                </Grid>
                <Grid item xs={12} md={5}>
                  <FormControl fullWidth sx={{ backgroundColor: 'transperant', color: 'secondary.main', borderRadius: '15px' }}>
                    <TextField
                      value={formik.values.amountToRaise ? formik.values.amountToRaise : ''}
                      name="amountToRaise"
                      onChange={formik.handleChange}
                      type="text"
                      InputProps={{ inputProps: { min: 1 } }}
                      disabled={formik.values.volunteerParticipant > 1 || formik.values.child}
                      sx={{
                        height: '45px',
                        color: 'secondary.main',
                        '&.MuiOutlinedInput-input::placeholder': {
                          color: 'secondary.contrastText',
                        },
                        '&.MuiOutlinedInput-root': {
                          background: 'secondary.main',
                          borderRadius: '15px',
                          border: 'none',
                        },
                        '& fieldset': {
                          borderColor: isDarkMode ? 'null' : 'secondary.dark',
                        },
                      }}
                      size="small"
                      onKeyDown={event => {
                        if (event.key === 'Backspace' || event.key === 'Tab') {
                          return true;
                        }
                        if (!/^[0-9]+$/.test(event.key)) {
                          event.preventDefault();
                        }
                      }}
                      placeholder="Amount"
                    />
                  </FormControl>
                  {formik.values.includeMicroFunding && formik.touched.amountToRaise && formik.values.amountToRaise === '' ? (
                    <Typography variant="caption" sx={{ color: 'error.main' }}>
                      Amount is required
                    </Typography>
                  ) : null}
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid container direction="row" alignItems="center" sx={{ mb: 1.2 }}>
            <Grid item xs={12} md={6}>
              <Grid container direction="row" alignItems="center">
                {/* post as organisation */}
                <Grid item xs={12} md={12}>
                  <FormControl
                    sx={{
                      backgroundColor: 'secondary.dark',
                      color: 'secondary.main',
                      borderRadius: '15px',
                      width: '100%',
                    }}
                  >
                    <Select
                      size="small"
                      displayEmpty
                      name="organizationId"
                      fullWidth
                      placeholder="Post as"
                      labelId="organization-id"
                      id="organizationId"
                      disabled={formik.values.child || formik.values.volunteerParticipant > 1}
                      startAdornment={
                        !postAsSelectedValue && isCreateActivity ? (
                          <div style={{ display: 'flex', alignItems: 'center', width: '-webkit-fill-available' }}>
                            <span style={{ fontSize: '.75rem', color: isDarkMode.darkMode ? '#a6a6a6' : '#dadada' }}>Post as</span>
                          </div>
                        ) : null
                      }
                      value={
                        isCreateActivity
                          ? postAsSelectedValue === 'My Self'
                            ? 'My Self'
                            : postAsSelectedValue === ''
                            ? ''
                            : formik.values.organizationId
                          : formik.values.organizationId
                          ? formik.values.organizationId
                          : 'My Self'
                      }
                      onChange={event => {
                        const selectedValue = event.target.value;
                        setPostAsSelectedValue(selectedValue);

                        formik.handleChange(event);
                        const isPostAsOrganisation = selectedValue !== 'My Self';
                        formik.setFieldValue('postAsOrganisation', isPostAsOrganisation);
                        formik.setFieldValue('organizationId', isPostAsOrganisation ? selectedValue : null);
                      }}
                      MenuProps={{
                        PaperProps: {
                          style: {
                            overflowY: 'scroll',
                          },
                        },
                        MenuListProps: {
                          style: {
                            maxHeight: '200px',
                          },
                        },
                      }}
                      sx={{
                        border: 'none',
                        fontSize: '.75rem',
                        '&.MuiMenu-paper': {
                          backgroundColor: '#FF5500',
                          borderRadius: '15px',
                          border: 'none',
                        },
                        '&.MuiOutlinedInput-root': {
                          background: 'secondary.main',
                          borderRadius: '15px',
                          height: 45,
                          border: 'none',
                          '& fieldset': {
                            border: '1px solid #BFBFBF',
                          },
                        },
                      }}
                    >
                      <MenuItem value="My Self" sx={{ fontSize: '.75rem' }}>
                        Myself
                      </MenuItem>
                      {userGrantedOrganizations
                        ?.filter(item => !item.deleted)
                        .map(item => (
                          <MenuItem value={item.id} key={item.id} sx={{ fontSize: '.75rem' }}>
                            {item.label}
                          </MenuItem>
                        ))}
                    </Select>
                  </FormControl>
                  {formik.touched.postAsOrganisation && formik.errors.postAsOrganisation && (
                    <Typography variant="caption" sx={{ color: 'error.main' }}>
                      {formik.touched.postAsOrganisation && formik.errors.postAsOrganisation ? formik.errors.postAsOrganisation : ''}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
      <Stack
        direction="column"
        gap={1}
        sx={{
          maxWidth: { xs: '100%' },
          borderRadius: '15px',
          border: formik.touched?.multimedia && formik.values?.multimedia?.length < 1 ? '1px solid #F6F7F8' : '1px solid #bfbfbf',
          backgroundColor: 'secondary.dark',
          p: { xs: 2, lg: 3 },
          mb: 1.5,
        }}
      >
        {multiMedia?.length > 0 ? (
          <Stack direction="column" sx={{ width: '100%' }} spacing={1}>
            <Carousel
              dynamicHeight={true}
              showThumbs={false}
              showStatus={true}
              showArrows={true}
              showIndicators={false}
              useKeyboardArrows={true}
              selectedItem={selectedImageIndex}
              autoPlay={true}
              interval={2000}
              onChange={index => setSelectedImageIndex(index)}
            >
              {multiMedia?.length > 0 &&
                multiMedia?.map((item, index) => (
                  <Box sx={{ position: 'relative', width: '100%' }} key={index}>
                    <DeleteIcon
                      sx={{
                        color: 'error.main',
                        background: '#fafafa',
                        position: 'absolute',
                        top: 5,
                        right: 40,
                        cursor: 'pointer',
                        borderRadius: '40%',
                        zIndex: 999999,
                      }}
                      onClick={() => handleImageClear(item.id)}
                    />
                    <div>
                      {item.type === 'images' ? (
                        <img src={item?.url} alt={`slide${index + 1}`} style={{ height: '130px', objectFit: 'cover' }} />
                      ) : item.type === 'videos' ? (
                        <Box sx={{ height: '130px' }} className="player-wrapper">
                          <VideoPlayer
                            config={config}
                            className="react-player"
                            url={item?.url}
                            width={'100%'}
                            height={'100%'}
                            muted
                            playing
                            playerStyle={{ padding: '0 25px' }}
                          />
                        </Box>
                      ) : null}
                    </div>
                  </Box>
                ))}
            </Carousel>
            <Stack direction="row" alignItems={'center'} justifyContent={'flex-end'}>
              <div {...getRootProps()}>
                <input {...getInputProps()} name={'multimedia'} />
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: 'primary.light',
                    color: 'common.white',
                    '&:hover': {
                      color: 'common.white',
                      backgroundColor: 'primary.light',
                      boxShadow: 'none',
                    },
                  }}
                  disabled={formik.values.volunteerParticipant > 1 || formik.values.child}
                >
                  + Add more
                </Button>
              </div>
            </Stack>
          </Stack>
        ) : (
          <Box>
            <div {...getRootProps()}>
              <input {...getInputProps()} name={'multimedia'} />
              <Grid
                container
                direction={'row'}
                alignItems={'center'}
                height={130}
                justifyContent={'center'}
                sx={{
                  borderRadius: '15px',
                  backgroundColor: theme.palette.mode === 'dark' ? '' : '#FFFF',
                  cursor: 'pointer',
                  px: 2,
                }}
              >
                <Stack direction={'row'} gap={2}>
                  <FileUploadOutlinedIcon />
                  <Typography
                    sx={{
                      color: theme.textColor.mainHeading,
                      fontSize: theme.p1.semiBold.fontSize,
                      fontWeight: theme.p1.semiBold.fontWeight,
                    }}
                  >
                    Drop your image files here, or <span style={{ color: 'primary.main' }}>browse</span>
                    <br></br>(Accepted file formats: JPG, JPEG, PNG,
                    {formik.values.subCategoryId !== 72 && <span>MP4, WEBM, OGG</span>})
                  </Typography>
                </Stack>
              </Grid>
            </div>
          </Box>
        )}
        {errorMessage && (
          <div style={{ margin: '10px 0px', fontFamily: 'Manrope', fontWeight: '400', fontSize: '12px', color: ' #E23A44' }}>
            {errorMessage}
          </div>
        )}
      </Stack>
      {formik.touched.multimedia && formik.values.multimedia.length <= 0 ? (
        <Typography variant="caption" sx={{ color: 'error.main', marginTop: '-25px' }}>
          Image is required
        </Typography>
      ) : null}
      {formik.values.categoryID === 19 && formik?.values?.subCategoryID !== 72 && (
        <Grid item xs={12} md={12}>
          <FormControl fullWidth sx={{ backgroundColor: 'transperant', color: 'secondary.main', borderRadius: '15px' }} variant="outlined">
            <OutlinedInput
              autoComplete="off"
              placeholder="Place of activity *"
              sx={{
                height: '45px',
                fontSize: '12px',
                color: 'secondary.main',
                border: '1px solid #BFBFBF',
                '& .MuiOutlinedInput-input::placeholder': {
                  color: isDarkMode.darkMode ? '#a6a6a6	' : 'grey',
                },
                '& .MuiOutlinedInput-root': {
                  background: 'secondary.main',
                  borderRadius: '15px',
                  border: 'none',
                  fontSize: '.75rem',
                },
                '&:focus-within,&:hover': {
                  border: 'none',
                },
                '& fieldset': {
                  borderColor: 'transparent',
                },
              }}
              size="small"
              type={'text'}
              value={
                formik.values.place
                  ? formik.values.place
                  : formik.values.latitude && formik.values.longitude
                  ? `Lat: ${formik.values.latitude}, Long: ${formik.values.longitude}`
                  : ''
              }
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              name="latitude"
              readOnly
              onClick={handleOpenModal}
              disabled={formik.values.child}
            />
            {formik.touched.latitude &&
            formik.values.latitude === '' &&
            formik.values.longitude === '' &&
            formik.values.online === false &&
            !mapTouched ? (
              <Typography variant="caption" sx={{ color: 'error.main', mt: 0.5 }}>
                Location is required
              </Typography>
            ) : null}
          </FormControl>
          {formik.values.latitude !== '' && formik.values.longitude !== '' && formik.values.online === false && (
            <Grid container direction="row" sx={{ borerRadius: '16px' }}>
              <StaticGMapWithMarker width={'100%'} latitude={formik.values.latitude} longitude={formik.values.longitude} />
            </Grid>
          )}
        </Grid>
      )}
      {formik.values.categoryID !== 19 && (
        <Stack direction={'column'} alignItems={'center'} gap={3}>
          <Grid container direction={'row'} gap={{ xs: 2, md: 0.5, lg: 3.5 }} alignItems={'center'} justifyContent={'space-between'}>
            <Grid item xs={12} md>
              {/* online event */}
              <FormControl component="fieldset">
                <FormControlLabel
                  sx={{ color: 'secondary.main', fontSize: '14px' }}
                  control={
                    <Checkbox
                      name="online"
                      checked={formik.values.online}
                      disabled={formik.values.child || (formik.values?.recurring && formik.values.volunteerParticipant > 1)}
                      onChange={event => {
                        formik.setFieldValue('online', event.target.checked);
                        formik.setFieldValue('onlineUrl', '');

                        if (!event.target.checked) {
                          // Reset online-related fields
                          formik.setFieldValue('onlineUrl', '');
                        } else {
                          // Reset offline-related fields
                          formik.setFieldValue('latitude', '');
                          formik.setFieldValue('longitude', '');
                          formik.setFieldValue('place', '');
                          formik.setFieldValue('country', '');
                          formik.setFieldValue('city', '');
                        }
                      }}
                      disableRipple
                      sx={{
                        color: 'transparent',

                        marginLeft: '5px',
                        '&.Mui-checked': { color: 'primary.light' },
                        '& .MuiSvgIcon-root': { fontSize: 23, borderRadius: '25%', border: '1px solid #BFBFBF', padding: '3px' },
                      }}
                    />
                  }
                  label={
                    <Typography
                      sx={{
                        color: theme.textColor.mainHeading,
                        fontSize: theme.p1.semiBold.fontSize,
                        fontWeight: theme.p1.semiBold.fontWeight,
                      }}
                    >
                      Make this an online event
                    </Typography>
                  }
                  labelPlacement="end"
                />
              </FormControl>
            </Grid>

            <Grid item xs={12} md sx={{ border: 'red' }}>
              {formik.values.online === true ? (
                <FormControl
                  fullWidth
                  sx={{ backgroundColor: 'transperant', color: 'secondary.main', borderRadius: '15px', cursor: 'pointer' }}
                  variant="outlined"
                >
                  <OutlinedInput
                    autoComplete="off"
                    name="onlineUrl"
                    onChange={handleUrlChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.onlineUrl}
                    sx={{
                      height: '45px',
                      fontSize: '.75rem',
                      color: 'secondary.main',
                      '&.MuiOutlinedInput-input::placeholder': {
                        color: 'secondary.contrastText',
                      },
                      '&.MuiOutlinedInput-root': {
                        background: 'secondary.main',
                        borderRadius: '15px',
                        border: 'none',
                      },
                      '& fieldset': {
                        borderColor: isDarkMode ? 'null' : 'secondary.dark',
                      },
                    }}
                    size="small"
                    type={'text'}
                    placeholder="Web link for the Activity *"
                    error={!!errorRemote}
                    disabled={formik.values.child}
                  />
                  {formik.touched.onlineUrl && formik.errors.onlineUrl && (
                    <Typography variant="caption" sx={{ color: 'error.main' }}>
                      {formik.touched.onlineUrl && formik.errors.onlineUrl ? formik.errors.onlineUrl.toString() : ''}
                    </Typography>
                  )}
                </FormControl>
              ) : (
                <FormControl
                  fullWidth
                  sx={{ backgroundColor: 'transperant', color: 'secondary.main', borderRadius: '15px' }}
                  variant="outlined"
                >
                  <OutlinedInput
                    autoComplete="off"
                    placeholder="Place of activity *"
                    sx={{
                      height: '45px',
                      fontSize: '.75rem',
                      color: 'secondary.main',
                      border: '1px solid #BFBFBF',
                      '& .MuiOutlinedInput-input::placeholder': {
                        color: isDarkMode.darkMode ? '#cbcbcb' : 'grey',
                      },
                      '& .MuiOutlinedInput-root': {
                        background: 'secondary.main',
                        borderRadius: '15px',
                        border: 'none',
                        fontSize: '.75rem',
                      },
                      '&:focus-within,&:hover': {
                        border: 'none',
                      },
                      '& fieldset': {
                        borderColor: 'transparent',
                      },
                    }}
                    size="small"
                    type={'text'}
                    value={
                      formik.values.place
                        ? formik.values.place
                        : formik.values.latitude && formik.values.longitude
                        ? `Lat: ${formik.values.latitude}, Long: ${formik.values.longitude}`
                        : ''
                    }
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    name="latitude"
                    readOnly
                    onClick={handleOpenModal}
                    disabled={formik.values.child}
                  />
                  {formik.touched.latitude &&
                  formik.values.latitude === '' &&
                  formik.values.longitude === '' &&
                  formik.values.online === false &&
                  !mapTouched ? (
                    <Typography variant="caption" sx={{ color: 'error.main', mt: 0.5 }}>
                      Location is required
                    </Typography>
                  ) : null}
                </FormControl>
              )}
            </Grid>
          </Grid>
          {formik.values.latitude !== '' && formik.values.longitude !== '' && formik.values.online === false && (
            <Grid container direction="row" sx={{ borerRadius: '16px' }}>
              <StaticGMapWithMarker width={'100%'} latitude={formik.values.latitude} longitude={formik.values.longitude} />
            </Grid>
          )}
          <Grid container direction={'row'} gap={{ xs: 2, md: 0.5 }} alignItems={'center'} justifyContent={'space-between'}>
            <Grid item container direction="column" xs={12} md sx={{ marginRight: { md: '18px' } }} gap={1}>
              <div>
                <DatePicker
                  value={dayjs(formik.values.startDate)}
                  defaultValue={dayjs(new Date())}
                  disabled={formik.values.child || (formik.values?.recurring && formik.values.volunteerParticipant > 1)}
                  label="Start date"
                  sx={{
                    borderRadius: '15px',
                    color: theme.textColor.paragraph,
                    width: '100%',
                    '& .MuiInputBase-root': {
                      borderRadius: '15px',
                      height: '45px',
                      pb: 0.4,
                      fontSize: '.75rem',
                      border: 'none',
                      '& fieldset': {
                        border: '1px solid #BFBFBF',
                      },
                    },
                    '& .MuiInputAdornment-root': {
                      padding: 0,
                    },
                    '& .react-datepicker-wrapper': {
                      zIndex: 9999,
                    },
                  }}
                  minDate={dayjs(new Date())}
                  onChange={newValue => {
                    const enteredDate = dayjs(newValue);
                    if (formik.values?.activityStartTime) {
                      const selectedTime = dayjs(formik.values?.activityStartTime);
                      const startDateDatePart = dayjs(newValue).format('YYYY-MM-DD');
                      const newActivityStartTime = dayjs(`${startDateDatePart}T${selectedTime.format('HH:mm:ss')}`).toDate();
                      formik.setFieldValue('activityStartTime', newActivityStartTime);
                    }

                    if (formik.values?.activityEndTime) {
                      const selectedTime = dayjs(formik.values?.activityEndTime);
                      const startDateDatePart = dayjs(newValue).format('YYYY-MM-DD');
                      const newActivityStartTime = dayjs(`${startDateDatePart}T${selectedTime.format('HH:mm:ss')}`).toDate();
                      formik.setFieldValue('activityEndTime', newActivityStartTime);
                    }

                    if (formik.values?.categoryID === 19) {
                      formik.setFieldValue('activityFrequencyId', 1);
                    } else {
                      formik.setFieldValue(
                        'activityFrequencyId',
                        formik.values?.activityFrequencyId ? formik.values?.activityFrequencyId : ''
                      );
                    }

                    if (enteredDate.isBefore(dayjs(new Date()), 'day')) {
                      formik.setFieldValue('startDate', '');
                    } else {
                      if (formik.values.activityStartTime) {
                        const activityStartTime = dayjs(formik.values.activityStartTime);
                        const newStartDate = enteredDate
                          .set('hour', activityStartTime.hour())
                          .set('minute', activityStartTime.minute())
                          .set('second', activityStartTime.second())
                          .toDate();

                        if (formik.values?.activityFrequencyId === 2) {
                          const newEndDateNormal = dayjs(newStartDate).add(1, 'day');
                          const endDateMax = dayjs(newStartDate).add(30, 'day');
                          setEndDateMax(endDateMax);
                          setEndDateNormal(newEndDateNormal);
                          formik.setFieldValue('endDate', newEndDateNormal);
                        }
                        if (formik.values?.activityFrequencyId === 3) {
                          const startDate = dayjs(newStartDate);
                          const newEndDateNormal = startDate.add(1, 'week').day(startDate.day());
                          const endDateMax = startDate.add(12, 'week');
                          setEndDateNormal(newEndDateNormal);
                          setEndDateMax(endDateMax);
                          formik.setFieldValue('endDate', newEndDateNormal);
                        }
                        if (formik.values?.activityFrequencyId === 4) {
                          const startDate = dayjs(newStartDate);
                          const newEndDateNormal = startDate.add(1, 'month').date(startDate.date());
                          const endDateMax = startDate.add(3, 'month');
                          setEndDateNormal(newEndDateNormal);
                          setEndDateMax(endDateMax);
                          formik.setFieldValue('endDate', newEndDateNormal);
                        }

                        formik.setFieldValue('startDate', newStartDate);
                      } else {
                        formik.setFieldValue('startDate', dayjs(newValue));
                        if (formik.values?.activityFrequencyId === 2) {
                          const newEndDateNormal = dayjs(newValue).add(1, 'day');
                          const endDateMax = dayjs(newValue).add(30, 'day');
                          setEndDateMax(endDateMax);
                          setEndDateNormal(newEndDateNormal);
                          formik.setFieldValue('endDate', newEndDateNormal);
                        }
                        if (formik.values?.activityFrequencyId === 3) {
                          const startDate = dayjs(newValue);
                          const newEndDateNormal = startDate.add(1, 'week').day(startDate.day());
                          const endDateMax = startDate.add(12, 'week');
                          setEndDateNormal(newEndDateNormal);
                          setEndDateMax(endDateMax);
                          formik.setFieldValue('endDate', newEndDateNormal);
                        }
                        if (formik.values?.activityFrequencyId === 4) {
                          const startDate = dayjs(newValue);
                          const newEndDateNormal = startDate.add(1, 'month').date(startDate.date());
                          const endDateMax = startDate.add(3, 'month');
                          setEndDateNormal(newEndDateNormal);
                          setEndDateMax(endDateMax);
                          formik.setFieldValue('endDate', newEndDateNormal);
                        }
                      }
                    }
                  }}
                />
              </div>
              {formik.errors.startDate && Boolean(formik.errors.startDate) && (
                <Typography variant="caption" sx={{ color: 'error.main' }}>
                  {formik.touched.startDate && formik.errors.startDate ? 'Start date must be on or after today' : ''}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} md>
              <FormControl fullWidth sx={{ backgroundColor: 'secondary.dark', borderRadius: '18px' }}>
                <Select
                  size="small"
                  displayEmpty
                  fullWidth
                  name="activityFrequencyId"
                  placeholder="Activity frequency"
                  labelId="Activity frequency"
                  id="activityFrequencyId"
                  value={formik.values.activityFrequencyId}
                  disabled={formik.values.child || formik.values.volunteerParticipant > 1 || formik.values?.categoryID === 19}
                  onChange={handleSelectFrequencies}
                  onBlur={formik.handleBlur}
                  startAdornment={
                    !formik.values.activityFrequencyId ? (
                      <div style={{ display: 'flex', alignItems: 'center', width: '-webkit-fill-available' }}>
                        <RepeatOutlinedIcon fontSize="small" sx={{ mr: 2, color: theme.textColor.paragraph }} />
                        <span style={{ fontSize: '.75rem', color: isDarkMode.darkMode ? '#a6a6a6' : '#dadada' }}>Frequency*</span>
                      </div>
                    ) : null
                  }
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: '300px',
                        overflowY: 'scroll',
                      },
                    },
                    MenuListProps: {
                      style: {
                        maxHeight: '300px',
                      },
                    },
                  }}
                  sx={{
                    border: 'none',
                    '&.MuiMenu-paper': {
                      borderRadius: '15px',
                      border: 'none',
                    },
                    '&.MuiOutlinedInput-root': {
                      borderRadius: '15px',
                      height: 45,
                      fontSize: '.75rem',
                      border: 'none',
                      '& fieldset': {
                        border: '1px solid #BFBFBF',
                      },
                    },
                  }}
                >
                  {activityFrequencies?.map((category: any) => (
                    <MenuItem
                      value={category.id}
                      key={category.id}
                      onClick={() => {
                        if (category.id === 5) {
                          handleCustomizeClick();
                          setIsEndDate(false);
                          const newEndDateNormal = dayjs(formik.values.startDate).add(1, 'day');
                          setSelectedEndDate(newEndDateNormal.toISOString().slice(0, 10));
                        } else if (category.id === 2 || category.id === 3 || category.id === 4) {
                          handleEndDate();
                          if (category.id === 2) {
                            const newEndDateNormal = dayjs(formik.values.startDate).add(1, 'day');
                            const endDateMax = dayjs(formik.values.startDate).add(30, 'day');
                            setEndDateMax(endDateMax);
                            setEndDateNormal(newEndDateNormal);
                            formik.setFieldValue('endDate', newEndDateNormal);
                          }
                          if (category.id === 3) {
                            const startDate = dayjs(formik.values.startDate);
                            const newEndDateNormal = startDate.add(1, 'week').day(startDate.day());
                            const endDateMax = startDate.add(12, 'week');
                            setEndDateNormal(newEndDateNormal);
                            setEndDateMax(endDateMax);
                            formik.setFieldValue('endDate', newEndDateNormal);
                          }
                          if (category.id === 4) {
                            const startDate = dayjs(formik.values.startDate);
                            const newEndDateNormal = startDate.add(1, 'month').date(startDate.date());
                            const endDateMax = startDate.add(3, 'month');
                            setEndDateNormal(newEndDateNormal);
                            setEndDateMax(endDateMax);
                            formik.setFieldValue('endDate', newEndDateNormal);
                          }
                        } else {
                          setIsEndDate(false);
                          formik.setFieldValue('endDate', '');
                          formik.setFieldValue('customFrequency[0].weeks', 0);
                          formik.setFieldValue('customFrequency[0].days', 0);
                          formik.setFieldValue('customFrequency[0].months', 0);
                          formik.setFieldValue('customFrequency[0].weekDays', []);
                        }
                      }}
                    >
                      <Stack direction={'row'} pl={1} gap={0.2}>
                        <Typography sx={{ textTransform: 'capitalize', fontSize: '.75rem' }}>{category.label}</Typography>
                      </Stack>
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              {formik.errors.activityFrequencyId && Boolean(formik.errors.activityFrequencyId) && (
                <Typography variant="caption" sx={{ color: 'error.main' }}>
                  {formik.touched.activityFrequencyId && formik.errors.activityFrequencyId
                    ? formik.errors.activityFrequencyId.toString()
                    : ''}
                </Typography>
              )}
            </Grid>
            {isEndDate && (
              <Grid item container direction="column" xs={12} md sx={{ marginLeft: { md: '18px' } }} gap={1}>
                <div>
                  <DatePicker
                    value={dayjs(formik.values.endDate)}
                    disabled={formik.values.child || formik.values.volunteerParticipant > 1 || formik.values.activityFrequencyId === 5}
                    defaultValue={dayjs(endDateNormal)}
                    onChange={newValue => {
                      const enteredDate = dayjs(newValue);
                      if (enteredDate.isBefore(dayjs(formik.values.starDate), 'day')) {
                        formik.setFieldValue('endDate', '');
                      } else {
                        formik.setFieldValue('endDate', dayjs(newValue));
                      }
                    }}
                    label="End date"
                    sx={{
                      borderRadius: '15px',
                      borderColor: 'red !important',
                      color: theme.textColor.paragraph,
                      fontSize: '.75rem',
                      width: '100%',
                      '& .MuiInputBase-root': {
                        borderRadius: '15px',
                        height: '45px',
                        pb: 0.4,
                        fontSize: '.75rem',
                        border: 'none',
                        '& fieldset': {
                          border: '1px solid #BFBFBF',
                        },
                      },
                      '& .MuiInputAdornment-root': {
                        padding: 0,
                      },
                      '& .Mui-error': {
                        color: '#b2b1ac',
                      },
                    }}
                    disablePast
                    minDate={dayjs(endDateNormal)}
                    maxDate={dayjs(endDateMax)}
                  />
                </div>
                {formik.errors.endDate && Boolean(formik.errors.endDate) && (
                  <Typography variant="caption" sx={{ color: 'error.main' }}>
                    {formik.touched.endDate && formik.errors.endDate ? 'Activity end date should not be invalid' : ''}
                  </Typography>
                )}
              </Grid>
            )}

            {isCustomOpen && (
              <>
                <div
                  style={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    zIndex: 9998,
                  }}
                  onClick={handleCustomModal}
                ></div>
                <div
                  style={{
                    position: 'fixed',
                    top: '13%',
                    left: window.innerWidth <= 768 ? '10%' : '38%',
                    zIndex: '9999',
                    width: window.innerWidth <= 768 ? '300px' : '350px',
                    height: '400px',
                    boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.5)',
                    background: isDark ? darkTheme.palette.secondary.dark : theme.palette.secondary.dark,
                    borderRadius: '15px',
                    padding: '20px',
                  }}
                >
                  <div
                    className="d-flex mb-2"
                    style={{
                      font: 'normal normal 600 20px/29px Manrope',
                      color: isDark ? darkTheme.palette.secondary.main : theme.palette.secondary.main,
                    }}
                  >
                    Custom occurrence
                  </div>
                  <div className="d-flex">
                    <label
                      className="me-2 mt-2"
                      style={{
                        font: 'normal normal normal 16px/26px Manrope',
                        color: isDark ? darkTheme.palette.secondary.main : theme.palette.secondary.main,
                      }}
                    >
                      Repeat every
                    </label>
                    {renderRequiredDaysDropdown()}

                    <select
                      className="ms-2 px-3"
                      value={requiredDaysUnit}
                      onChange={handleRequiredDaysUnitChange}
                      style={{
                        backgroundColor: '#F6F7F8',
                        borderRadius: '32px',
                        border: 'none',
                        color: '#676E7B',
                        height: '40px',
                      }}
                    >
                      <option value="day">day(s)</option>
                      <option value="week">week(s)</option>
                      <option value="month">month(s)</option>
                    </select>
                  </div>

                  <div className="d-flex mb-3 mt-3">
                    {requiredDaysUnit === 'week' && (
                      <div>
                        {dayAbbreviations.map(dayAbbreviation => {
                          const dayFullName = dayFullNameMap[dayAbbreviation];
                          const isDaySelected = formik.values.customFrequency[0]?.weekDays.includes(dayFullName);

                          return (
                            <Chip
                              key={dayAbbreviation}
                              label={dayAbbreviation}
                              variant="outlined"
                              style={{
                                background: isDaySelected ? '#674BFF' : '#F6F7F8',
                                borderRadius: '32px',
                                marginRight: '6px',
                                marginBottom: '6px',
                                color: isDaySelected ? '#FFFF' : '#676E7B',
                              }}
                              onClick={() => handleDayOfWeekToggle(dayFullName)}
                            />
                          );
                        })}
                      </div>
                    )}
                  </div>
                  <div className="d-flex mt-4 mb-5">
                    <label
                      htmlFor="requiredDays"
                      className="me-3 mt-2"
                      style={{
                        font: 'normal normal normal 16px/26px Manrope',
                        color: isDark ? darkTheme.palette.secondary.main : theme.palette.secondary.main,
                      }}
                    >
                      Ends on
                    </label>
                    <div>
                      <input
                        type="date"
                        id="endDate"
                        name="endDate"
                        value={selectedEndDate}
                        onChange={handleEndDateChange}
                        min={endDateMin ? endDateMin.toISOString().slice(0, 10) : ''}
                        max={endDateMaxCust ? endDateMaxCust.toISOString().slice(0, 10) : ''}
                        className="px-3"
                        style={{
                          background: '#F6F7F8',
                          borderRadius: '32px',
                          border: 'none',
                          color: '#676E7B',
                          height: '40px',
                        }}
                      />
                    </div>
                  </div>
                  <div className="d-flex flex-row align-items-end justify-content-end mt-5" style={{ position: 'sticky', top: ' 68%' }}>
                    <button
                      className="me-2 px-4"
                      style={{
                        borderRadius: '15px',
                        background: 'none',
                        border: 'none',
                        font: 'normal normal normal 16px/26px Manrope',
                        color: isDark ? '#CCCCCC' : '#494c52',
                      }}
                      onClick={handleCustomModal}
                    >
                      Cancel
                    </button>
                    <button
                      className="me-2 px-4"
                      style={{
                        borderRadius: '15px',
                        border: 'none',
                        font: 'normal normal normal 16px/26px Manrope',
                        color: '#292D34',
                        background:
                          !selectedEndDate || (requiredDaysUnit === 'week' && selectedDaysOfWeek.length === 0)
                            ? isDark
                              ? '#666666'
                              : '#E0E0E0'
                            : isDark
                            ? '#CCCCCC'
                            : '#FFCE00',
                      }}
                      onClick={handleCustomDoneModal}
                      disabled={!selectedEndDate || (requiredDaysUnit === 'week' && selectedDaysOfWeek.length === 0)}
                    >
                      Done
                    </button>
                  </div>
                </div>
              </>
            )}
          </Grid>
          <Grid container direction={'row'} gap={{ xs: 2, md: 0.5 }} alignItems={'center'} justifyContent={'space-between'}>
            {/* Activity start time */}
            <Grid item xs={12} md sx={{ marginRight: { md: '18px' } }}>
              <ActivityStartTime formik={formik} />
            </Grid>
            <Grid item xs={12} md>
              <ActivityEndTime formik={formik} />
            </Grid>
          </Grid>
        </Stack>
      )}
      {formik.values.subCategoryID === 72 && (
        <Grid container direction={'row'} gap={{ xs: 2, md: 0.5 }} alignItems={'center'} justifyContent={'space-between'}>
          <LostPet formik={formik} />
        </Grid>
      )}

      <Dialog
        fullScreen={fullScreen}
        open={isModalOpen}
        onClose={handleCloseModal}
        PaperProps={{
          sx: {
            borderRadius: { xs: 0, sm: '15px' },
            boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
          },
        }}
      >
        <MapBoxMapView
          handleCloseModal={handleCloseModal}
          formik={formik}
          handleSelectLocation={handleSelectLocation}
          handleLocationFromSearchBox={handleLocationFromSearchBox}
        />
      </Dialog>
    </Grid>
  );
};
export default CreateActivityBody;
