import React, { useState } from 'react';
import { Button, useMediaQuery, useTheme } from '@mui/material';
import SignPetitionModal from 'app/components/common/modals/petition-sign-modal/sign-petition-modal';
import LinearProgressBar from 'app/components/common/progress-bar/linear-progress-bar';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import './petition-details-sidebar.scss';
import { toggleModalLogin } from 'app/shared/reducers/authentication';
import toast from 'react-hot-toast';
import { fetchBanned, fetchBlocked } from 'app/shared/reducers/profile';

const PetitionDetailSideBar = ({ petitionResponse, onPetitionClicked }) => {
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const [currentComponent, setCurrentComponent] = React.useState(' ');
  const [authModalOpen, setAuthModalOpen] = React.useState(false);
  const [open, setOpen] = useState(false);
  const [message, setMessage] = React.useState(' ');
  const [hasSigned, setHasSigned] = useState<boolean>(petitionResponse?.participant || false);
  const isSmallDevice = useMediaQuery(theme.breakpoints.down('sm'));
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [respectiveSign, setrespectiveSign] = useState(null);
  const dispatch = useAppDispatch();

  const handleSignButtonClick = async () => {
    if (!isAuthenticated) {
      setCurrentComponent('login');
      dispatch(toggleModalLogin());

      setAuthModalOpen(true);
      return;
    }

    if (petitionResponse?.participant) {
      setHasSigned(true);
      onPetitionClicked(true);
    } else {
      const res = await dispatch(fetchBanned({ userId: JSON.parse(localStorage.getItem('MyDetailsAccount'))?.id }));
      if (res?.payload) {
        toast.error(<>Action Restricted: You cannot perform this action as you are banned by the admin.</>);
        return;
      }
      const res1 = await dispatch(fetchBlocked({ userId: petitionResponse?.createdBy }));
      if (res1?.payload) {
        toast.error(<>Action Restricted: You cannot sign this petition.</>);
        return;
      }
      setOpen(true);
      setMessage('You have signed because ... ( Optional )');
    }
  };
  const handleModalClose = async data => {
    if (data === 'submission') {
      onPetitionClicked(true);
    }
    setOpen(false);
  };

  const petitionCountStyle = {
    font: 'normal normal 600 14px/23px Manrope',
    color: isDarkMode ? theme.palette.secondary.contrastText : '#292D34',
  };

  return (
    <>
      <div
        className="col-12 col-md-3 mt-2 mt-md-1 p-0  w-100 mob-100"
        style={{ background: isDarkMode ? '#2c2c38' : '#fff', height: '100vh', borderRadius: '15px', textAlign: 'center' }}
      >
        <div className="pt-3" style={{ background: isDarkMode ? '#2c2c38' : '#fff' }}>
          {Number(petitionResponse?.petitionCount) === Number(petitionResponse?.targetSignature) ? (
            <div className="mt-2 ps-2 ">
              <p style={{ color: '#674BFF', font: 'normal normal 600 13px/21px Manrope', cursor: 'default' }}>Successful!</p>
            </div>
          ) : (
            <>
              <span
                className="ps-2 text-center"
                style={{ ...petitionCountStyle }}
              >{`${petitionResponse?.petitionCount} signed of ${petitionResponse?.targetSignature}`}</span>
              <div className="row mt-1 ps-2">
                <div className="col-2"></div>
                <div className="col-8">
                  <LinearProgressBar
                    currentValue={parseFloat(petitionResponse?.petitionCount)}
                    maxValue={parseFloat(petitionResponse?.targetSignature)}
                  />
                </div>
              </div>
              <div className="mt-2 ps-2 ">
                <p
                  style={{
                    font: 'normal normal normal 12px/21px Manrope',
                    color: theme.palette.mode === 'dark' ? theme.palette.secondary.contrastText : '#9FA1A4',
                  }}
                >
                  {' '}
                  {petitionResponse?.petitionCountToday === 1
                    ? `${petitionResponse?.petitionCountToday} person signed today`
                    : `${petitionResponse?.petitionCountToday} people signed today`}
                </p>
                {hasSigned || petitionResponse?.participant ? (
                  <p
                    style={{
                      color: theme.palette.mode === 'dark' ? '#ffce00' : theme.palette.primary.light,
                      font: 'normal normal 600 13px/21px Manrope',
                    }}
                  >
                    You have already signed
                  </p>
                ) : (
                  <div className="col-12 text-center ">
                    {petitionResponse?.petitionStatus !== 'COMPLETED' && (
                      <Button
                        type="submit"
                        variant="contained"
                        className="col-12"
                       sx={{
                        backgroundColor:  '#ffce00',
                        textTransform: 'none',
                        borderRadius: '15px',
                        color:'black',
                        height: '40px',
                        width: isSmallDevice ? '88%' : '190px',
                        position: isSmallDevice ? 'fixed' : 'unset',
                        bottom: isSmallDevice ? '20px' : 'unset',
                        right: isSmallDevice ? '21px' : 'unset',
                        zIndex: isSmallDevice ? 1000 : '',
                         '&:hover': {
                        backgroundColor:'#ffce00',
                          color: 'black',
                      },
                      }}
                        onClick={handleSignButtonClick}
                      >
                        Sign the petition
                      </Button>
                    )}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>

      <SignPetitionModal
        fullScreen={fullScreen}
        id={petitionResponse?.id}
        respectiveId={respectiveSign}
        open={open}
        message={message}
        onClose={handleModalClose}
      />
    </>
  );
};

export default PetitionDetailSideBar;
