import { styled } from '@mui/material';

export const StyledTextarea = styled('textarea')`
  height: ${({ theme }) => (theme.palette.mode === 'dark' ? '150px' : '215px')};
  padding: 8px;
  width: 100%;
  background-color: ${({ theme }) => (theme.palette.mode === 'dark' ? theme.palette.secondary.dark : '#fff')};
  color: ${({ theme }) => (theme.palette.mode === 'dark' ? '#fff' : theme.palette.secondary.main)};
  border-radius: 25px;
  border: 1px solid #bfbfbf;
  transition: border-color 0.3s ease;
  &:focus {
    border-color: #ffce00;
    border-width: 2px;
    outline: none;
  }
   ${(props) => props.disabled && `color: lightgray;`}
`;
