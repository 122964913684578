import React, { useEffect, useState } from 'react';
import { Box, Typography, Collapse, useTheme } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from 'dayjs';
import { useAppDispatch } from 'app/config/store';
import { fetchCustomInstructions } from 'app/shared/reducers/volunteer';
import CustomRangeModal from '../activity-custom-calender/custom-calender-range';

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

const InstructionsComponent = (passedData: any) => {
  const [isVisible, setIsVisible] = useState(false);
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const isDarkMode = theme.palette.mode === 'dark';
  const [value, setValue] = useState<Dayjs | null>(null); // Initialize to null
  const [instructions, setInstructions] = useState<string[]>([]);
  const [instructionsData, setInstructionsData] = useState<Record<string, string[]>>({});

  const toggleVisibility = () => {
    setIsVisible(!isVisible);
  };

  useEffect(() => {
    const fetchInstructions = async () => {
      if (passedData) {
        console.log('recurringId', passedData);
        let response = await dispatch(fetchCustomInstructions({ activityId: passedData?.passedData?.recurringId }));
        if (response && response.payload) {
          console.log('romal', response);
          const payload = response.payload as Record<string, string[]>;

          const uniqueInstructionsData = Object.fromEntries(
            Object.entries(payload).map(([date, instructions]) => [date, Array.from(new Set(instructions))])
          );

          setInstructionsData(uniqueInstructionsData);
        }
      }
    };

    fetchInstructions();
  }, [passedData, dispatch]);
  const [calDialogOpen, setCalDialogOpen] = useState(false);

  const handleCalender = () => {
    setCalDialogOpen(true);
  };

  const shouldDisableDate = (date: Dayjs) => {
    const dateString = date.format('YYYY-MM-DD');
    return !instructionsData[dateString];
  };

  const handleDateChange = (newValue: Dayjs | null) => {
    console.log('Selected date:', newValue);
    setValue(newValue);
    if (newValue) {
      const dateString = newValue.format('YYYY-MM-DD');
      setInstructions(instructionsData[dateString] || ['No instructions available for this date.']);
      setIsVisible(true);
    } else {
      setInstructions([]);
      setIsVisible(false);
    }
  };
  console.log('Selected date:', value);
  const handleCloseModal = filteredDataId => {
    setCalDialogOpen(false);
  };

  return (
    <Box sx={{ background: 'unset', boxShadow: 'none', my: 3 }}>
      <style>
        {`
                .css-17qar0e-MuiInputBase-input-MuiOutlinedInput-input {
                    display: none !important;
                }
                .css-9425fu-MuiOutlinedInput-notchedOutline { 
                    display: none !important;
                }
                .css-12qs7hp-MuiInputBase-input-MuiOutlinedInput-input {
                    display: none !important;
                }
                .css-1d3z3hw-MuiOutlinedInput-notchedOutline { 
                    display: none !important;
                }
                .custom-list {
                    list-style-type: none;
                    padding-left: 0;
                }
                .custom-list li {
                    position: relative;
                    padding-left: 25px;
                    margin-bottom: 8px;
                }
                .custom-list li::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%);
                    width: 9px;
                    height: 9px;
                    border-radius: 50%;
                    background-color: #775FFF;
                }
                @media only screen and (max-width: 1024px) {
                    .css-1452zj8-MuiTypography-root,
                    .css-hsfylk-MuiTypography-root {
                        width: 100%;
                        white-space: nowrap;
                    }
                    .date-c {
                        display: none !important;
                    }
                    .css-24rejj-MuiInputBase-input-MuiOutlinedInput-input,
                    .css-nrutr0-MuiInputBase-input-MuiOutlinedInput-input {
                        text-align: end;
                    }
                    .css-24rejj-MuiInputBase-input-MuiOutlinedInput-input {
                        color: #775fff;
                    }
                    .css-nrutr0-MuiInputBase-input-MuiOutlinedInput-input {
                        color: #ffce00 !important;
                    }
                    .css-1vlv06b-MuiInputBase-root-MuiOutlinedInput-root {
                        background: unset !important;
                    }
                }
                `}
      </style>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          cursor: 'pointer',
        }}
      >
        <Typography
          onClick={toggleVisibility}
          aria-controls="panel1-content"
          id="panel1-header"
          sx={{ color: isDarkMode ? '#FFCE00' : '#674BFF' }}
        >
          View Specific Instructions
        </Typography>

        {/* <DatePicker
          sx={{ color: isDarkMode ? '#FFCE00' : '#674BFF' }}
          value={value}
          onChange={handleDateChange}
          shouldDisableDate={shouldDisableDate}
        /> */}
        <CalendarMonthIcon
          onClick={handleCalender}
          sx={{ height: '22px', width: '25px', color: isDarkMode ? '#FFCE00' : '#674BFF', cursor: 'pointer' }}
        />

        <CustomRangeModal
          open={calDialogOpen}
          onClose={handleCloseModal}
          handleData={instructionsData}
          handleDateChange={handleDateChange}
        />

        {/* Conditionally render the date display */}
        {value && (
          <Typography className="ms-auto " sx={{ color: isDarkMode ? '#FFCE00' : '#674BFF', fontSize: '13px' }}>
            {dayjs(value).format('D MMM YYYY')}
          </Typography>
        )}
      </Box>

      <Collapse in={isVisible} timeout="auto" unmountOnExit>
        <Box sx={{ mt: 2, px: 2, fontSize: '14px', overflowY: 'scroll', scrollbarWidth: 'thin', maxHeight: '80px' }}>
          <ul className="custom-list">
            {instructions.map((instruction, index) => (
              <li key={index}>{instruction}</li>
            ))}
            {instructions?.length == 0 && <span>No instructions for selected date.</span>}
          </ul>
        </Box>
      </Collapse>
    </Box>
  );
};

export default InstructionsComponent;
