import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, useLocation } from 'react-router-dom';

import AppBar from '@mui/material/AppBar';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import SideNavigation from 'app/components/layout/side-navigaion/side-navigation';
import PrimaryHeader from 'app/components/layout/header/primary-header';
import { fetchProfile, fetchUnAuthorizedProfile } from 'app/shared/reducers/profile';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import AmyIcon from 'app/components/common/cards/chat-card/amy-icons';
import toast from 'react-hot-toast';
import { Button, Card, Container, Grid, List, ListItem, Typography, useMediaQuery } from '@mui/material';
import AuthorisationModal from 'app/components/common/authorization-modal/authorization-modal';
import { resetLogin, toggleModalLogin } from 'app/shared/reducers/authentication';
import axios from 'axios';
import { Link } from '@mui/material';
import NewPrimarySearchAppBar from 'app/components/layout/header/new-primary-header';
import RelevantLinks from '../relevant-links/relevant-links';

const drawerWidth = 315;
const drawerWidthSm = 248;

interface Props {
  window?: () => Window;
}
const LegelNotices = () => {
  // const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const [currentComponent, setCurrentComponent] = React.useState(' ');
  const [authModalOpen, setAuthModalOpen] = React.useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };
  const [category, setCategory] = useState('');
  const [subCategory, setSubCategory] = useState('');
  const theme = useTheme();
  const dispatch = useAppDispatch();
  const isDarkMode = theme.palette.mode === 'dark';
  const [toggleValue, setToggleValue] = useState(false);
  const [toggleMapValue, setToggleMapValue] = useState(false);
  const [searchKeyword, setSearchKeyword] = React.useState<string>('');
  const [fieldsChanged, setFieldsChanged] = useState(false);
  const [routeBack, setRouteBack] = useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const location = useLocation();

  const isBackHome = location.state?.isBackHome;
  const [htmlContent, setHtmlContent] = useState('');
  // const isBackHome = true;
  const fetchAboutUs = async () => {
    try {
      const response = await axios.get<any>(`api/staticContent/legel-notices`);
      console.log('community-guidelines', response);
      setHtmlContent(response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAboutUs();
  }, []);
  useEffect(() => {
    if (isBackHome) {
      window.history.pushState(null, null, window.location.pathname);
      window.addEventListener('popstate', e => {
        e.preventDefault();
        window.history.pushState(null, null, window.location.pathname);
      });
    }
  }, []);

  const handleSubCategoryClick = (category: string, subCategory: string) => {
    setCategory(category);
    setSubCategory(subCategory);
  };
  const handleAccordionClick = (category: any, currentState: any) => {
    setCategory(category);
  };
  const handleNavigationArrowButton = () => {
    if (fieldsChanged) {
      setRouteBack(true);
    } else if (isBackHome) {
      navigate('/');
    } else {
      navigate(-1);
    }
  };
  const handleButtonClicked = () => {
    const newToggleValue = !toggleValue;
    if (toggleMapValue) {
      setToggleMapValue(false);
    }
    setToggleValue(newToggleValue);
  };

  const handleButtonMapClicked = () => {
    const newToggleMapValue = !toggleMapValue;
    if (toggleValue) {
      setToggleValue(false);
    }
    setToggleMapValue(newToggleMapValue);
  };
  const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
  }));

  const handleButtonFeatureClicked = () => {};

  const drawer = (
    <Box
      sx={{
        border: 'none',
        maxHeight: '100vh',
        overflowY: 'scroll',
        overflowX: 'hidden',

        background:
          theme.palette.mode === 'dark'
            ? '#1F1F26'
            : 'transparent linear-gradient(182deg, #F7F5EF 0%, #F2F2FA 49%, #f1f1f8 100%) 0% 0% no-repeat padding-box;',
        maxWidth: { sm: drawerWidthSm, lg: drawerWidth, md: drawerWidthSm },
        backdropFilter: 'blur(10px)',
        minHeight: '100%',
        '&::-webkit-scrollbar': {
          display: 'none',
        },
        '&::-webkit-scrollbar-thumb': { backgroundColor: 'secondary.contrastText', height: '10px' },
        '&::-webkit-scrollbar-track': {
          backgroundColor: 'transparent',
        },
      }}
    >
      <DrawerHeader sx={{ justifyContent: 'center', height: '79px', border: 'none' }}>
        <Box style={{ paddingLeft: '0px', paddingRight: '50px', cursor: 'pointer' }} onClick={() => navigate('/')}>
          <AmyIcon sx={{ width: '159px', height: '100px' }} />
        </Box>
      </DrawerHeader>

      <SideNavigation
        category={category}
        subCategory={subCategory}
        onSubCategoryClick={handleSubCategoryClick}
        onhandleAccordionClick={handleAccordionClick}
        onButtonClicked={handleButtonClicked}
        toggleMapValue={toggleMapValue}
        setToggleMapValue={setToggleMapValue}
        onMapClicked={handleButtonMapClicked}
        onFeatureClicked={handleButtonFeatureClicked}
      />
      <Divider />
    </Box>
  );

  // const container = window !== undefined ? () => window().document.body : undefined;
  return (
    <Box>
      <NewPrimarySearchAppBar searchKeyword={searchKeyword} setSearchKeyword={setSearchKeyword} />
      <Box
        sx={{
          maxWidth: '800px',
          width: '100%',
          margin: 'auto',
          overflowY: 'scroll',
          height: '100vh',
          paddingBottom: '120px',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          msOverflowStyle: 'none',
          scrollbarWidth: 'none',
        }}
      >
        <Card style={{ background: isDarkMode ? '#2c2c38' : '' }}>
          <Container maxWidth="md" sx={{ paddingY: 4 }}>
            <Typography variant="h4" gutterBottom>
              Legal Notices
            </Typography>
            <Typography variant="body1" paragraph>
              Welcome to ReachAMY Inc.'s Legal Notices page. Please read the following information carefully, as it governs your use of our
              website and mobile app. By accessing or using our platform, you agree to comply with these legal terms and conditions.
            </Typography>

            <Typography variant="h5" gutterBottom>
              1. Copyright Notice
            </Typography>
            <List>
              <ListItem>
                All content on the ReachAMY Inc. website and mobile app, including text, graphics, logos, images, audio clips, digital
                downloads, and software, is the property of ReachAMY Inc. or its content suppliers and is protected by copyright laws.
                Unauthorized use of any content on this platform may violate copyright, trademark, and other laws.
              </ListItem>
            </List>

            <Typography variant="h5" gutterBottom>
              2. Trademarks
            </Typography>
            <List>
              <ListItem>
                The ReachAMY Inc. name, logo, and all related names, logos, product and service names, designs, and slogans are trademarks
                of ReachAMY Inc. or its affiliates or licensors. You may not use these trademarks without the prior written permission of
                ReachAMY Inc. or the respective trademark owner.
              </ListItem>
            </List>

            <Typography variant="h5" gutterBottom>
              3. Disclaimer of Warranties
            </Typography>
            <List>
              <ListItem>
                a. General Disclaimer: The information, content, and materials on the ReachAMY Inc. platform are provided "as is" and
                without warranties of any kind, either express or implied. ReachAMY Inc. disclaims all warranties, express or implied,
                including but not limited to, implied warranties of merchantability and fitness for a particular purpose.
              </ListItem>
              <ListItem>
                b. Accuracy of Information: While we strive to provide accurate and up-to-date information, ReachAMY Inc. does not warrant
                that the information on its platform is accurate, complete, or current. We reserve the right to modify the content at any
                time without prior notice.
              </ListItem>
            </List>

            <Typography variant="h5" gutterBottom>
              4. Limitation of Liability
            </Typography>
            <List>
              <ListItem>
                In no event shall ReachAMY Inc., its affiliates, directors, officers, employees, agents, or suppliers be liable for any
                indirect, incidental, special, consequential, or punitive damages, including without limitation, loss of profits, data, use,
                goodwill, or other intangible losses, arising out of or in connection with your use of the platform or any content on it,
                whether based on warranty, contract, tort (including negligence), or any other legal theory, even if advised of the
                possibility of such damages.
              </ListItem>
            </List>

            <Typography variant="h5" gutterBottom>
              5. Indemnification
            </Typography>
            <List>
              <ListItem>
                You agree to indemnify and hold harmless ReachAMY Inc., its affiliates, officers, directors, employees, agents, and
                licensors from and against any and all claims, liabilities, damages, losses, costs, expenses, or fees (including reasonable
                attorneys' fees) arising from your use of the ReachAMY Inc. platform, violation of these legal notices, or infringement of
                any intellectual property or other right of any person or entity.
              </ListItem>
            </List>

            <Typography variant="h5" gutterBottom>
              6. Governing Law and Jurisdiction
            </Typography>
            <List>
              <ListItem>
                These legal notices shall be governed by and construed in accordance with the laws of the State of Delaware, United States,
                without regard to its conflicts of law principles. You agree that any legal action or proceeding related to your use of the
                ReachAMY Inc. platform shall be brought exclusively in a federal or state court of competent jurisdiction sitting in
                Delaware.
              </ListItem>
            </List>

            <Typography variant="h5" gutterBottom>
              7. Changes to Legal Notices
            </Typography>
            <List>
              <ListItem>
                ReachAMY Inc. reserves the right to update or modify these legal notices at any time without prior notice. We encourage you
                to review this page periodically for any changes. Your continued use of the ReachAMY Inc. platform after the posting of
                changes constitutes your acceptance of such changes.
              </ListItem>
            </List>

            <Typography variant="h5" gutterBottom>
              8. Contact Us
            </Typography>

            <List>
              <ListItem sx={{ flexDirection: 'column', alignItems: 'flex-start',flexWrap:' nowrap' }}>
                <Typography>If you have any questions about these legal notices, please contact us at </Typography>
                <Link href="mailto:legal@reachamy.com" sx={{ color: isDarkMode ? '#FFCE00;' : '#674bff' }}>
                  legal@reachamy.com
                </Link>
              </ListItem>
            </List>
            <RelevantLinks/>
          </Container>
        </Card>
      </Box>
    </Box>
  );
};

export default LegelNotices;
