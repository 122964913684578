import React, { useEffect, useState, useCallback } from 'react';

import { Button, Stack, useTheme, Box, Typography, Grid } from '@mui/material';
import { Carousel } from 'react-responsive-carousel';
import { singleImageType } from 'app/shared/reducers/image-source';
import DeleteIcon from '@mui/icons-material/Delete';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { useDropzone } from 'react-dropzone';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { apiEndPoints } from 'app/utils/data/constants/api-end-points';
import AddIcon from '@mui/icons-material/Add';
import VideoPlayer from 'app/components/common/video-player/video-player';

interface ActivityRatingImageUploadProps {
  formik: any;
  multiMedia: { id: number }[];
  setMultiMedia: any;
}
const ActivityRatingImageUpload = ({ formik, multiMedia, setMultiMedia }: ActivityRatingImageUploadProps) => {
  console.log('romal rating');
  const [localMultimedia, setLocalMultimedia] = useState<singleImageType[]>([]);
  const [files, setFiles] = useState<File[]>([]);

  const [totalImages, setTotalImages] = useState(0);
  const [totalSize, setTotalSize] = useState(0);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const theme = useTheme();
  const isDarkMode = theme.palette.mode === 'dark';
  const { id } = useParams();
  const acceptingFiles = {
    'image/jpeg': [],
    'image/png': [],
    'image/jpg': [],
    'video/mp4': [],
    'video/webm': [],
    'video/ogg': [],
  };
  const onDrop = useCallback(
    (accFiles: File[], FileRejection: any) => {
      const acceptedFiles = accFiles.map(file => ({ file, errors: [] }));
      if (totalImages + acceptedFiles.length <= 10) {
        let valid = true;
        for (const file of acceptedFiles) {
          if (file.file.size > 20 * 1024 * 1024) {
            valid = false;
            setErrorMessage('File size must be less than 20MB.');
            break;
          }
        }
        if (valid) {
          setErrorMessage('');
          setFiles(acceptedFiles.map(entry => entry.file));
          setTotalImages(totalImages + acceptedFiles.length);
          setTotalSize(existingSize => acceptedFiles.reduce((acc, file) => acc + file.file.size, existingSize));
        } else {
          setErrorMessage('File size must be less than 20MB.');
        }
      } else {
        setErrorMessage('You can upload a maximum of 10 images.');
      }
    },
    [totalImages]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: acceptingFiles,
    multiple: true,
  });

  useEffect(() => {
    if (files !== undefined) {
      const sendFiles = async () => {
        try {
          for (const file of files) {
            const formData = new FormData();
            formData.append('file', file);
            const uploadEndpoint = file.type.includes('video') ? apiEndPoints.videoUpload : apiEndPoints?.imageUpload;
            const response = await axios.post<any>(uploadEndpoint, formData, {
              headers: { 'Content-Type': 'multipart/form-data' },
            });
            if (response.status === 201) {
              setErrorMessage('');
              const newMedia = response.data;

              console.log('romal rating', newMedia);
              setMultiMedia(state => {
                const newState = [...state, newMedia];
                formik.setFieldValue('multimedia', newState);
                return newState;
              });
              // Update localMultimedia
              setLocalMultimedia(state => [...state, newMedia]);
            }
          }
        } catch (error) {
          console.log(error);
        }
      };
      sendFiles();
    }
  }, [files]);
  console.log('localMultimedia', localMultimedia);

  useEffect(() => {
    if (formik.values.multimedia?.length <= 0) {
      formik.setFieldError('multimedia', 'Multimedia is required');
    } else {
      formik.setFieldError('multimedia', '');
    }
  }, [formik.values.multimedia]);

  useEffect(() => {
    if (id && formik.values?.multimedia) {
      setLocalMultimedia(formik.values?.multimedia);
    }
  }, [id, formik.values.multimedia]);
  const handleImageClear = id => {
    const newLocalMultiMedia = [...localMultimedia];
    const deletedImageIndex = newLocalMultiMedia.findIndex(item => item.id === id);
    if (deletedImageIndex !== -1) {
      setErrorMessage('');
      newLocalMultiMedia.splice(deletedImageIndex, 1);
      setLocalMultimedia(newLocalMultiMedia);
      const newMultiMedia = multiMedia.filter(media => media.id !== id);
      setMultiMedia(newMultiMedia);
      setTotalImages(newMultiMedia.length);
      if (newMultiMedia.length > 0) {
        setSelectedImageIndex(0);
      }
    }
  };
  useEffect(() => {
    formik.setFieldValue('multimedia', multiMedia);
  }, [multiMedia]);
  const config = {
    file: {
      attributes: {
        controlsList: 'nodownload',
        disablePictureInPicture: true,
        controls: 'nodownload',
      },
    },
  };
  return (
    <>
      <Stack
        direction="column"
        gap={0.3}
        sx={{
          maxWidth: { xs: '100%' },
          padding: 0,
        }}
      >
        {localMultimedia?.length > 0 ? (
          <Stack direction="column" sx={{ width: '300px' }} spacing={1}>
            <Carousel
              dynamicHeight={true}
              showThumbs={false}
              showStatus={true}
              showArrows={true}
              showIndicators={false}
              useKeyboardArrows={true}
              selectedItem={selectedImageIndex}
              autoPlay={true}
              interval={1000}
            >
              {localMultimedia?.length > 0 &&
                formik.values.multimedia?.map((item, index) => (
                  <Box sx={{ position: 'relative', width: '300px' }} key={index}>
                    <DeleteIcon
                      sx={{
                        color: 'error.main',
                        background: '#fafafa',
                        position: 'absolute',
                        top: 5,
                        right: 40,
                        cursor: 'pointer',
                        borderRadius: '40%',
                      }}
                      onClick={() => handleImageClear(item.id)}
                    />
                    <div>
                      {item.type === 'images' ? (
                        <img src={item?.url} alt={`slide${index + 1}`} style={{ height: '130px', objectFit: 'cover' }} />
                      ) : item.type === 'videos' ? (
                        <Box sx={{ height: '130px' }} className="player-wrapper">
                          <VideoPlayer
                            config={config}
                            className="react-player"
                            url={item?.url}
                            width={'100%'}
                            height={'100%'}
                            muted
                            playing
                            playerStyle={{ padding: '0 25px' }}
                          />
                        </Box>
                      ) : null}
                    </div>
                  </Box>
                ))}
            </Carousel>
            <Stack direction="row" alignItems={'center'} justifyContent={'flex-end'}>
              <div {...getRootProps()}>
                <input {...getInputProps()} name={'multimedia'} />
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: 'primary.light',
                    color: 'common.white',
                    '&:hover': {
                      color: 'common.white',
                      backgroundColor: 'primary.light',
                      boxShadow: 'none',
                    },
                  }}
                >
                  + Add more
                </Button>
              </div>
            </Stack>
          </Stack>
        ) : (
          <Box>
            <div {...getRootProps()}>
              <input {...getInputProps()} name={'multimedia'} />
              <Grid container justifyContent={'flex-start'}>
                <Button
                  variant="contained"
                  sx={{
                    background: '#EFF0F0',
                    '&:hover': {
                      background: '#EFF0F0',
                    },
                    width: '50px',
                    height: '50px',
                    borderRadius: '10px',
                  }}
                >
                  <AddIcon />
                </Button>
              </Grid>
            </div>
          </Box>
        )}
        {errorMessage && (
          <div style={{ margin: '10px 0px', fontFamily: 'Manrope', fontWeight: '400', fontSize: '12px', color: ' #E23A44' }}>
            {errorMessage}
          </div>
        )}
      </Stack>
    </>
  );
};

export default ActivityRatingImageUpload;
