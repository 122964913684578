import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';

import {
  Avatar,
  Card,
  CardActions,
  CardContent,
  Tooltip,
  Button,
  IconButton,
  Stack,
  useTheme,
  Typography,
  ListItem,
  ListItemText,
  useMediaQuery,
  Dialog,
  Box,
  Grid,
  DialogContent,
  DialogActions,
  Paper,
  AvatarGroup,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Popover,
  ListItemButton,
  Checkbox,
  ListItemIcon,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { Carousel } from 'react-responsive-carousel';
import { useNavigate } from 'react-router-dom';
import LanguageIcon from '@mui/icons-material/Language';
import { useAppDispatch, useAppSelector } from 'app/config/store';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import UpVoteFilled from 'app/components/icons/activity-card/up-vote-filled';
import SquareChatIcon from 'app/components/icons/header-icons/square-chat-icon';
import ShareIcon from 'app/components/icons/activity-card/share-icon';
import BlueTwitterIcon from 'app/components/icons/login-page-icons/blue-twitter-icon';
import GrayTwitterIcon from 'app/components/icons/login-page-icons/gray-twitter-icon';
import FacebookIcon from 'app/components/icons/login-page-icons/facebook-icon';
import LinkedInIcon from 'app/components/icons/login-page-icons/linkedin-icon';
import MoreHorizOutlinedIcon from '@mui/icons-material/MoreHorizOutlined';
import VideoPlayer from '../../video-player/video-player';
import BookmarkBorderIcon from '@mui/icons-material/BookmarkBorder';
import BookmarkIcon from '@mui/icons-material/Bookmark';
import { getPhoneNumber, toggleModalLogin } from 'app/shared/reducers/authentication';
import AuthorisationModal from '../../authorization-modal/authorization-modal';
import { fetchBookmarkedActivity, isRemoteActivity, isRemoteChildrenActivity } from 'app/shared/reducers/bookmark-activity';
import {
  convertDateToFormattedString,
  convertDateToYear,
  encodedTitle,
  generateGMapsLinkCords,
  generateGMapsLinkPlace,
  getFormattedTime,
  getInitials,
  shareOnSocialMedia,
  timestampToHoursAgo,
  truncateString,
} from 'app/utils/common-utils/common-utils';
import RemoteEventIcon from 'app/components/icons/activity-card/remote-event';
import UpVoteOutlined from 'app/components/icons/activity-card/up-vote-outlined';
import { fetchLikes } from 'app/shared/reducers/activity-like';
import { fetchActivityShareToken, fetchRecurringDetails, fetchRecurringDetailsFilter } from 'app/shared/reducers/activity-home';
import JoiningSuccessModal from '../../modals/joining-success-modal';
import { participateInActivityCard } from 'app/shared/reducers/volunteer';
import { fetchBanned, fetchBookMarkedItems, fetchProfileDetails } from 'app/shared/reducers/profile';
import { fetchOrganizationDetails } from 'app/shared/reducers/organization';
import CloseIcon from '@mui/icons-material/Close';
import ReportActivityDialog from 'app/components/ui/activity-details-section/activity-resource/activity-report';
import LeaveActivityRecurringDialog from 'app/components/ui/activity-details-section/activity-resource/activity-recurring-leave';
import GenericModal from '../../modals/generic-modal';
import { appConstants } from 'app/utils/data/constants/constants';
import toast from 'react-hot-toast';
import { filterActivities, updatePrevItemId, updateVolunteeringEventId } from 'app/shared/reducers/activity-filter';
import ScheduleSendOutlinedIcon from '@mui/icons-material/ScheduleSendOutlined';
import ShareModal from 'app/components/common/modals/share-modal/share-modal';
import axios from 'axios';
import FeatureDialog from '../../alert-dialog/feature-dialog';
import InviteMoreModal from '../../modals/invite-more/invite-more';
import Moment from 'react-moment';
import PhoneVerification from 'app/components/ui/phone-verification/phone-verification';
import ImageGrid from 'app/components/ui/grid-layout/ImageGrid';
import Lightbox from 'yet-another-react-lightbox';
import Video from 'yet-another-react-lightbox/plugins/video';
import 'yet-another-react-lightbox/styles.css';
import { GallerySlide } from 'app/components/ui/activity-details-section/activity-details-card/activity-details-card';
import VisibilityIcon from '@mui/icons-material/Visibility';
import './activity-recurring-card.scss';
import InstructionsComponent from '../activity-specific-instruction/activity-specific-instructions';
<style>
  {`.css-rsdpij-MuiPaper-root-MuiDialog-paper {
    border-radius: 15px !important;}`}
</style>;

interface SingleActivityReccuringCardProps {
  activity: any;
  onBookmarkSuccess: () => void;
  onLikeSuccess: () => void;
  handleOrgsDialog?: (value?: boolean) => void;
  handleInstructionsModal?: (value: boolean) => void;
  handleActivityId?: (value: any) => void;
  selectedOrgId?: number;
  isVolunteerModalOpen?: boolean;
  handleCancel?: () => void;
  selectedOption?: string;
  changeOrgId?: (value: number) => void;
  selectedVolunteerOptions: any;
  setSelectedVolunteerOptions: any;
  selectedDeleteOptions: any;
  setSelectedDeletedOptions: any;
  handleDeleteRecurringClick: (id: string) => void;
  currentActivityId?: number;
  getCurrentActivity?: (id: number) => void;
  clearCurrentActivity?: () => void;
  eventGallery: { link: string; type: string }[];
  handleDeleteClick: (id: string) => void;
  fromProfile?: boolean;
  commonParams?: any;
}
const SingleActivityReccuringCard = ({
  activity,
  onBookmarkSuccess,
  onLikeSuccess,
  handleOrgsDialog,
  selectedOrgId,
  handleInstructionsModal,
  handleActivityId,
  isVolunteerModalOpen,
  handleCancel,
  selectedOption,
  changeOrgId,
  selectedVolunteerOptions,
  setSelectedVolunteerOptions,
  selectedDeleteOptions,
  setSelectedDeletedOptions,
  handleDeleteRecurringClick,
  currentActivityId,
  getCurrentActivity,
  handleDeleteClick,
  fromProfile,
  commonParams,
}: SingleActivityReccuringCardProps) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const isAuthenticated = useAppSelector(state => state.authentication.isAuthenticated);
  const { filter, eventType } = useAppSelector(state => state.filters.RequestBody);
  const myProfileId = useAppSelector(state => state.authentication.account.id);
  const isSmallerScreen = useMediaQuery(theme.breakpoints.down(400));
  const isNewScreen = useMediaQuery(theme.breakpoints.down(983));
  const isMdScreen = useMediaQuery(theme.breakpoints.down(992));
  const isSmallDevice = useMediaQuery(theme.breakpoints.down('sm'));

  const [isBookmarked, setIsBookmarked] = useState<boolean>(false);
  const [places, setPlaces] = useState<{ place: string; address: string }>({ place: '', address: '' });
  const isDarkMode = theme.palette.mode === 'dark';
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [socialAnchorEl, setSocialAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [currentComponent, setCurrentComponent] = React.useState(' ');
  const [authModalOpen, setAuthModalOpen] = React.useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isMobile = useMediaQuery('(max-width:600px)');
  const [openRemoteModal, setOpenRemoteModal] = useState<boolean>(false);
  const [isLiked, setIsLiked] = useState<boolean>(false);
  const [likeCountNumber, setLikeCountNumber] = useState<number>(0);
  const [childrens, setChildrens] = React.useState<any>([]);
  const [openModal, setOpenModal] = useState(false);
  const [reportDialogOpen, setReportDialogOpen] = useState(false);
  const [clickedActivity, setClickedActivity] = useState(null);
  const userOwnedOrganizations = useAppSelector(state => state.organizationReducer.userOwnedOrganization);
  const postedByStyle = {
    font: 'normal normal 600 16px/22px Manrope',
    color: isDarkMode ? theme.palette.secondary.contrastText : 'secondary.main',
  };

  const location = {
    font: 'normal normal normal 14px/23px Manrope',
    color: isDarkMode ? theme.palette.secondary.contrastText : '#676E7B',
  };

  const locationXs = {
    font: 'normal normal normal 14px/21px Manrope',
    color: isDarkMode ? theme.palette.secondary.contrastText : '#676E7B',
  };
  const handleShareButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setSocialAnchorEl(event.currentTarget);
  };
  const open = Boolean(anchorEl);
  const socialShare = Boolean(socialAnchorEl);
  const menuItemId = open ? 'simple-popover' : undefined;
  const socialShareId = socialShare ? 'simple-popover-social' : undefined;
  const handleSocialShareClose = () => {
    setSocialAnchorEl(null);
  };
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [isButtonClicked, setIsButtonClicked] = useState(false);
  const [isButtonVolunteeer, setIsButtonVolunteeer] = useState(false);
  const [leaveDialogOpen, setLeaveDialogOpen] = useState(false);

  const joinAndProceed = async () => {
    localStorage.setItem('parentId', activity?.id);
    setIsButtonClicked(true);
    try {
      let response;
      if (selectedOrgId === null || selectedOrgId === 1) {
        response = await dispatch(participateInActivityCard({ activityIds: selectedVolunteerOptions }));
        if (response) {
          fetchParticipantStatus();
          fetchParticipantChildrenStatus();
        }
      } else {
        response = await dispatch(participateInActivityCard({ activityIds: selectedVolunteerOptions, organizationId: selectedOrgId }));
        if (response) {
          fetchParticipantStatus();
          fetchParticipantChildrenStatus();
        }
      }

      if (response.meta.requestStatus == 'fulfilled') {
        localStorage.removeItem('respectiveActivityId');
        handleInstructionsModal(false);
        setIsButtonClicked(false);
        setOpenModal(true);
        setSelectedVolunteerOptions([]);
        handleCancel();
        handleOrgsDialog(false);
        if (fromProfile) {
          dispatch(fetchProfileDetails({ ...commonParams, eventType: 'Bookmarked' }));
        }
      } else {
        handleInstructionsModal(false);
        setSelectedVolunteerOptions([]);
        localStorage.removeItem('respectiveActivityId');
        handleOrgsDialog(false);
      }
    } catch (error) {
      console.error('Error joining the activity:', error);
    }
  };
  const [activityPostingRules, setactivityPostingRules] = useState<any>();
  useEffect(() => {
    setactivityPostingRules(JSON.parse(localStorage.getItem('postingRules')));
  }, [localStorage.getItem('postingRules')]);

  const VolunteerModal = (
    <Grid>
      {activity && clickedActivity === activity?.recurringId && (
        <Grid>
          <Dialog
            open={isVolunteerModalOpen && clickedActivity === activity?.recurringId}
            onClose={() => {
              handleInstructionsModal(false);

              handleCancel();
            }}
            PaperProps={{
              sx: {
                borderRadius: '15px',

                padding: '2%',
              },
            }}
          >
            <Stack direction="row" alignItems={'center'} justifyContent={'flex-end'}>
              <CloseIcon
                onClick={() => {
                  handleInstructionsModal(false);

                  handleCancel();
                }}
                sx={{ cursor: 'pointer' }}
              />
            </Stack>

            <DialogContent sx={{ height: 'auto', overflowY: 'hidden', paddingBottom: 0 }}>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                {activityPostingRules && activityPostingRules?.length > 0 ? (
                  <Grid>
                    <Typography sx={{ fontSize: '14px/22px', color: isDarkMode ? '#FFCE00' : '#674BFF', marginBottom: '10px' }}>
                      Instructions for volunteers
                    </Typography>

                    <Paper
                      elevation={3}
                      sx={{
                        backgroundImage: 'linear-gradient(rgba(255, 255, 255, 0.16), rgba(255, 255, 255, 0.16))',
                        p: 2,
                        height: '200px',
                        width: '100%',
                        overflowX: 'hidden',
                        boxShadow: 'none',
                        overflowY: activityPostingRules && activityPostingRules?.length > 2 ? 'scroll' : 'hidden',
                        scrollbarWidth: 'thin',
                        '&::-webkit-scrollbar': {
                          display: 'block',
                        },
                        '&::-webkit-scrollbar-track': {
                          backgroundColor: 'primary.light',
                        },
                        '&::-webkit-scrollbar-thumb': {
                          backgroundColor: '#AAA',
                          borderRadius: '16px',
                        },
                      }}
                    >
                      {activityPostingRules?.length
                        ? activityPostingRules?.map(item => (
                            <Stack key={item.id} direction="row" spacing={1} alignItems="flex-start" justifyContent={'flex-start'}>
                              <Stack p={0.7}>
                                <Box
                                  sx={{
                                    width: '9px',
                                    height: '9px',
                                    borderRadius: '50%',
                                    backgroundColor: '#775FFF',
                                  }}
                                />
                              </Stack>

                              <Stack direction="row" sx={{ color: theme.textColor.paragraph, gap: 0.2, px: { xs: 0 } }}>
                                <Typography sx={{ fontSize: theme.infoText.regular.fontSize }}>{item.description}</Typography>
                              </Stack>
                            </Stack>
                          ))
                        : null}
                    </Paper>
                  </Grid>
                ) : null}
              </div>

              <InstructionsComponent passedData={activity} />
            </DialogContent>

            <DialogActions
              sx={{
                justifyContent: 'center',
                textAlign: 'center',
              }}
            >
              <Stack
                direction={'column'}
                gap={1}
                sx={{
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Button
                  onClick={() => joinAndProceed()}
                  sx={{
                    background: theme.palette.primary.main,
                    color: '#292D34',
                    borderRadius: '15px',
                    width: '159px',
                    height: '40px',
                    '&:hover': {
                      background: theme.palette.primary.main,
                      color: '#292D34',
                      borderRadius: '15px',
                      width: '159px',
                      height: '40px',
                    },
                  }}
                  disabled={isButtonClicked}
                >
                  Join & proceed
                </Button>

                <Typography sx={{ fontSize: '14px', color: theme.textColor.heading }}>
                  By joining this event you accept the rules/instructions of this event.
                </Typography>
              </Stack>
            </DialogActions>
          </Dialog>
        </Grid>
      )}
    </Grid>
  );
  const openVolunteerModal = () => {
    if (isAuthenticated) {
      setIsButtonVolunteeer(true);
      if (clickedActivity === activity?.recurringId) handleInstructionsModal(true);
    } else {
      dispatch(toggleModalLogin());
      setCurrentComponent('login');

      setAuthModalOpen(true);
    }
  };
  const handleVolunteerAsRec = async () => {
    setClickedActivity(activity?.id);
    handleActivityId(activity?.id);
    if (isAuthenticated) {
      const myDetailsAccount = localStorage.getItem('MyDetailsAccount');
      const userId = myDetailsAccount ? JSON.parse(myDetailsAccount)?.id : null;
      const res = await dispatch(fetchBanned({ userId: userId }));
      if (res?.payload) {
        toast.error(<>Action Restricted: You cannot perform this action as you are banned by the admin.</>);
        return;
      }
      if (userOwnedOrganizations?.length > 0) {
        if (selectedOption) {
          handleOrgsDialog(true);
          openVolunteerModal();
        } else {
          handleOrgsDialog(true);
        }
      } else {
        handleOrgsDialog(true);
        changeOrgId(1);
        openVolunteerModal();
      }
    } else {
      setCurrentComponent('login');
      dispatch(toggleModalLogin());

      setAuthModalOpen(true);
    }
  };
  const [editActivityAnchorEl, setEditActivityAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const openOptions = Boolean(editActivityAnchorEl);
  const editActivityId = openOptions ? 'edit-activity-popover' : undefined;

  const [selectedOptions, setSelectedOptions] = useState<any[]>([]);
  const handleEditActivityClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    fetchData(activity?.recurringId, 'EDIT');
    setEditActivityAnchorEl(event.currentTarget);
    setLeaveActivityAnchorEl(null);
  };

  const handleEditActivityClose = () => {
    setEditActivityAnchorEl(null);
    setSelectedOptions([]);
  };
  const handleOptionClick = (option: any) => {
    if (option?.parent) {
      if (selectedOptions.includes(option?.id)) {
        setSelectedOptions([]);
      } else {
        const allIds = childrens.map(child => child.id);
        setSelectedOptions([...allIds, option?.id]);
      }
    } else {
      const selectedId = option?.id;
      const parent = childrens.find(item => item.parent);
      // if (selectedOptions.includes(parent?.id)) {
      //   setSelectedOptions(selectedOptions.filter(id => id !== parent?.id));
      // }
      if (selectedOptions.includes(option?.id)) {
        setSelectedOptions([]);
      } else {
        setSelectedOptions([option?.id]);
      }
    }
  };

  const handleOkButtonClick = () => {
    // handleEditActivityClose();
    const parent = childrens.find(item => item.parent);
    if (selectedOptions.includes(parent?.id)) {
      navigate(`/edit-activity/${parent?.id}`);
    } else {
      const path = selectedOptions[0];
      navigate(`/edit-activity/${path}`);
    }
  };
  const [leaveActivityAnchorEl, setLeaveActivityAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const openLeaveOptions = Boolean(leaveActivityAnchorEl);
  const leaveActivityId = openLeaveOptions ? 'leave-activity-popover' : undefined;

  const [selectedLeaveOptions, setSelectedLeaveOptions] = useState<any[]>([]);
  const handleLeaveActivityClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    dispatch(updateVolunteeringEventId(activity?.recurringId));
    fetchData(activity?.recurringId, 'LEAVE');
    setLeaveActivityAnchorEl(event.currentTarget);
    // setEditActivityAnchorEl(null);
  };

  const handleLeaveActivityClose = () => {
    setLeaveActivityAnchorEl(null);
    setSelectedLeaveOptions([]);
  };
  const handleOptionLeaveClick = (option: any) => {
    if (option?.parent) {
      if (selectedLeaveOptions.includes(option?.id)) {
        setSelectedLeaveOptions([]);
      } else {
        const allIds = childrens.map(child => child.id);
        setSelectedLeaveOptions([...allIds, option?.id]);
      }
    } else {
      const selectedId = option?.id;
      const parent = childrens.find(item => item.parent);
      if (selectedLeaveOptions.includes(parent?.id)) {
        setSelectedLeaveOptions(selectedLeaveOptions.filter(id => id !== parent?.id));
      }
      const checkParent = selectedLeaveOptions.includes(parent?.id) ? [] : selectedLeaveOptions;
      if (checkParent.includes(selectedId)) {
        setSelectedLeaveOptions(checkParent.filter(id => id !== selectedId));
      } else {
        setSelectedLeaveOptions(prevSelection =>
          prevSelection.includes(selectedId) ? prevSelection.filter(id => id !== selectedId) : [...prevSelection, selectedId]
        );
      }
    }
  };

  const handleLeaveButtonClick = () => {
    const parent = childrens.find(item => item.parent);
    if (selectedLeaveOptions?.length > 1 && parent && selectedLeaveOptions.includes(parent.id)) {
      setSelectedLeaveOptions([parent.id]);
    }
    // handleLeaveActivityClose();
    setLeaveDialogOpen(true);
  };
  const [confirmDelete, setConfirmDelete] = React.useState<boolean>(false);
  const [deleteActivityAnchorEl, setDeleteActivityAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const openDeleteOptions = Boolean(deleteActivityAnchorEl);
  const deleteActivityId = openLeaveOptions ? 'delete-activity-popover' : undefined;

  const handleDeleteActivityClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    fetchData(activity?.recurringId, 'DELETE');
    setDeleteActivityAnchorEl(event.currentTarget);
    // setEditActivityAnchorEl(null);
  };

  const [confirmFeature, setConfirmFeature] = React.useState<boolean>(false);
  const [payload, setPayload] = React.useState<any>();
  const [passId, setPassId] = React.useState<any>();

  const handleFeatureClick = (event: React.MouseEvent<HTMLButtonElement>, data: any) => {
    setPassId(data?.id);
    handleGetCatalogue(1);
  };
  const [getcurrentId, setCurrentId] = useState<any>();
  useEffect(() => {
    const fetchData = async () => {
      if (activity?.id !== undefined) {
        try {
          if (isAuthenticated) {
            const response = await dispatch(fetchRecurringDetails(activity?.id));
            if (response?.payload) {
              const filteredResponse = (response.payload as any[])?.filter(item => !item.parent);
              const currentDate = new Date().toISOString().split('T')[0];
              const sameDateItems = filteredResponse.filter(item => item.startDate.startsWith(currentDate));
              if (sameDateItems.length > 0) {
                setCurrentId(sameDateItems[0].id);
              }
            }
          }
        } catch (error) {
          console.error('Recurring failed:', error);
        }
      }
    };

    fetchData();
  }, [activity?.id]);
  const handleRemote = async () => {
    const body = {
      activityId: getcurrentId,
    };
    const response = await axios.post<any>(`/api/activity/announce-remote-arrival`, body);
  };
  const [paymentLoading, setPaymentLoading] = useState(false);
  const handlePayment = async () => {
    setPaymentLoading(true);

    let passData = {
      eventId: passId,
      eventType: 1,
      quantity: 1,
      productId: payload?.productId,
    };

    try {
      const response = await axios.post<any>('api/payment-service/checkout', passData);
      if (response.status === 201 || response.status === 200) {
        setPaymentLoading(false);

        const checkoutUrl = response?.data?.checkoutUrl;
        if (checkoutUrl) {
          window.location.href = checkoutUrl; // Open checkout URL in current tab
        } else {
          console.error('Checkout URL not found.');
        }
      }
    } catch (error) {
      setPaymentLoading(false);

      console.log(error);
    }
  };

  const handleReportClose = () => {
    setReportDialogOpen(false); // Close the dialog
    if (fromProfile) {
      dispatch(fetchProfileDetails({ ...commonParams, eventType: 'Bookmarked' }));
    }
  };
  const handleGetCatalogue = async id => {
    try {
      const response = await axios.get<any>(`api/payment-service/product-catalogue?eventType=${id}`);
      if (response.status === 201 || response.status === 200) {
        setPayload(response.data);
        setConfirmFeature(true);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleDeleteActivityClose = () => {
    setDeleteActivityAnchorEl(null);
    setSelectedDeletedOptions([]);
  };
  const handleOptionDeleteClick = (option: any) => {
    if (option?.parent) {
      if (selectedDeleteOptions?.includes(option?.id)) {
        setSelectedDeletedOptions([]);
      } else {
        const allIds = childrens.map(child => child.id);
        setSelectedDeletedOptions([...allIds, option?.id]);
      }
    } else {
      const selectedId = option?.id;
      const parent = childrens.find(item => item.parent);
      if (selectedDeleteOptions?.includes(parent?.id)) {
        setSelectedDeletedOptions(selectedDeleteOptions?.filter(id => id !== parent?.id));
      }
      const checkParent = selectedDeleteOptions?.includes(parent?.id) ? [] : selectedDeleteOptions;
      if (checkParent.includes(selectedId)) {
        setSelectedDeletedOptions(checkParent.filter(id => id !== selectedId));
      } else {
        setSelectedDeletedOptions(prevSelection =>
          prevSelection.includes(selectedId) ? prevSelection.filter(id => id !== selectedId) : [...prevSelection, selectedId]
        );
      }
    }
  };

  const handleDeleteButtonClick = () => {
    // handleLeaveActivityClose();
    const parent = childrens.find(item => item.parent);
    if (selectedDeleteOptions?.length > 1 && parent && selectedDeleteOptions?.includes(parent.id)) {
      setSelectedDeletedOptions([parent.id]);
    }
    setConfirmDelete(true);
  };

  const handleYesClick = () => {
    handleDeleteRecurringClick(selectedDeleteOptions);

    handleClose();
    setConfirmDelete(false);
    setDeleteActivityAnchorEl(null);
    setSelectedDeletedOptions([]);
  };

  const modalActionButtons = [
    { label: 'Yes', onClick: handleYesClick },
    { label: 'No', onClick: () => setConfirmDelete(false) },
  ];

  const [volunteerActivityAnchorEl, setVolunteerActivityAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const openVolunteer = Boolean(volunteerActivityAnchorEl);
  const volunteerActivityId = openVolunteer ? 'volunteer-options-popover' : undefined;
  const handleVolunteerOptionsClose = () => {
    setVolunteerActivityAnchorEl(null);
  };
  const [phoneVerificationModal, setPhoneVerificationModal] = useState<boolean>(false);
  const handleOkVolunteerClick = () => {
    if (phoneVerificationStatus) {
      const parent = childrens.find(item => item.parent);
      if (selectedVolunteerOptions?.length > 1 && parent && selectedVolunteerOptions.includes(parent.id)) {
        setSelectedVolunteerOptions([parent.id]);
      }
      handleVolunteerAsRec();
      handleVolunteerOptionsClose();
    } else {
      setPhoneVerificationModal(true);
    }
  };

  const handleOptionVolunteerClick = (option: any) => {
    if (option?.parent) {
      if (selectedVolunteerOptions.includes(option?.id)) {
        setSelectedVolunteerOptions([]);
      } else {
        const allIds = childrens?.map(child => child.id);
        setSelectedVolunteerOptions([...allIds, option?.id]);
      }
    } else {
      const selectedId = option?.id;
      const parent = childrens.find(item => item.parent);
      const childIds = childrens.filter(item => !item.parent).map(child => child.id);

      if (selectedVolunteerOptions.includes(parent?.id)) {
        setSelectedVolunteerOptions(selectedVolunteerOptions.filter(id => id !== parent?.id));
      }
      let updatedSelection = selectedVolunteerOptions.includes(selectedId)
        ? selectedVolunteerOptions.filter(id => id !== selectedId)
        : [...selectedVolunteerOptions, selectedId];
      const areAllChildrenSelected = childIds.every(id => updatedSelection.includes(id));
      if (areAllChildrenSelected) {
        updatedSelection = [...updatedSelection, parent?.id];
      } else {
        updatedSelection = updatedSelection.filter(id => id !== parent?.id);
      }

      setSelectedVolunteerOptions(updatedSelection);
    }
  };
  const handleUnauthClick = async () => {
    if (!isAuthenticated) {
      setCurrentComponent('login');
      dispatch(toggleModalLogin());
      setAuthModalOpen(true);
      return;
    }
  };

  const handleVolunteerOptionsClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setSelectedVolunteerOptions([]);
    handleCancel();
    dispatch(updateVolunteeringEventId(activity?.recurringId));
    setClickedActivity(activity?.recurringId);
    fetchData(activity?.recurringId, 'VOLUNTEER');
    setVolunteerActivityAnchorEl(event.currentTarget);
    setLeaveActivityAnchorEl(null);
    setEditActivityAnchorEl(null);
  };
  const [buttonText, setButtonText] = useState('');
  useEffect(() => {
    let text = '';
    if (activity?.showLeaveActivity) {
      text = isSmallDevice ? 'Thanks! Join More' : 'Thanks! Join More';
    } else {
      text = isSmallDevice ? 'Volunteer' : 'I want to volunteer';
    }
    setButtonText(text);
  }, [activity?.showLeaveActivity, isSmallDevice]);
  const handleBookmarkClick = async () => {
    if (!isAuthenticated) {
      setCurrentComponent('login');
      dispatch(toggleModalLogin());
      setAuthModalOpen(true);
      return;
    }

    setIsBookmarked(s => !s);

    try {
      const actionResult = await dispatch(fetchBookmarkedActivity(Number(activity?.recurringId)));
      if (fetchBookmarkedActivity.fulfilled.match(actionResult)) {
        onBookmarkSuccess();
        // dispatch(filterActivities({ filter, eventType, page: 0 }));
      }
    } catch (error) {
      console.error('Bookmarking failed:', error);

      setIsBookmarked(s => !s);
    }
  };

  const handleAuthModalClose = () => {
    setAuthModalOpen(false);
  };
  const [remote, setRemote] = useState<boolean>(false);
  const [isAnyChildParticipant, setIsAnyChildParticipant] = useState<boolean>(false);
  const [isNextChildParticipant, setIsNextChildParticipant] = useState<boolean>(false);
  useEffect(() => {
    const fetchData = async () => {
      if (activity?.recurringId) {
        try {
          const res = await dispatch(isRemoteActivity(activity?.recurringId));
          setRemote(res.payload);
        } catch (error) {
          console.error('Error fetching remote activity:', error);
        }
      }
    };

    fetchData();
  }, [activity?.recurringId]);
  useEffect(() => {
    const fetchData = async () => {
      if (activity?.recurringId) {
        try {
          const res = await dispatch(isRemoteChildrenActivity(activity?.recurringId));
          console.log('participant response', res);
          setIsAnyChildParticipant(res.payload?.isAnyChildParticipant);
          setIsNextChildParticipant(res.payload?.isNextChildParticipant);
        } catch (error) {
          console.error('Error fetching remote activity:', error);
        }
      }
    };

    fetchData();
  }, [activity?.recurringId]);
  const fetchParticipantStatus = async () => {
    if (activity?.recurringId) {
      try {
        const res = await dispatch(isRemoteActivity(activity?.recurringId));
        setRemote(res.payload);
      } catch (error) {
        console.error('Error fetching remote activity:', error);
      }
    }
  };

  const fetchParticipantChildrenStatus = async () => {
    if (activity?.recurringId) {
      try {
        const res = await dispatch(isRemoteChildrenActivity(activity?.recurringId));

        setIsAnyChildParticipant(res.payload?.isAnyChildParticipant);
        setIsNextChildParticipant(res.payload?.isNextChildParticipant);
      } catch (error) {
        console.error('Error fetching remote activity:', error);
      }
    }
  };

  const handleRemoteActivityButton = async () => {
    if (isAuthenticated) {
      if ((activity?.online && isNextChildParticipant && remote) || activity?.hasPermission) {
        setOpenRemoteModal(s => !s);
        // let res = await dispatch(isRemoteActivity(activity?.recurringId));
      }
    } else {
      dispatch(toggleModalLogin());
      setCurrentComponent('login');
      setAuthModalOpen(true);
    }
  };

  const toolTipText = !isAuthenticated
    ? null
    : isAuthenticated && !isNextChildParticipant && activity?.activityStatus !== 'ENDED' && activity?.activityStatus !== 'COMPLETED'
    ? 'If you want to access the link today, you will need to volunteer for the nearest upcoming activity first, or at least wait until the current running activity ends.'
    : !activity?.hasPermission &&
      isNextChildParticipant &&
      !remote &&
      activity?.activityStatus !== 'ENDED' &&
      activity?.activityStatus !== 'COMPLETED'
    ? 'Link will be published 30 mins before the activity starts'
    : activity?.activityStatus === 'ENDED' || activity?.activityStatus === 'COMPLETED'
    ? 'This activity has ended.'
    : null;
  const handleLikeClick = async () => {
    if (!isAuthenticated) {
      dispatch(toggleModalLogin());

      setCurrentComponent('login');
      setAuthModalOpen(true);

      return;
    }
    if (isAuthenticated) {
      const myDetailsAccount = localStorage.getItem('MyDetailsAccount');
      const userId = myDetailsAccount ? JSON.parse(myDetailsAccount)?.id : null;
      const res = await dispatch(fetchBanned({ userId: userId }));
      if (res?.payload) {
        toast.error(<>Action Restricted: You cannot perform this action as you are banned by the admin.</>);
        return;
      }
    }
    setIsLiked(s => !s);

    if (isLiked) {
      setLikeCountNumber(likeCountNumber - 1);
    } else {
      setLikeCountNumber(likeCountNumber + 1);
    }

    try {
      const actionResult = await dispatch(fetchLikes(Number(activity?.recurringId)));
      if (fetchLikes.fulfilled.match(actionResult)) {
        onLikeSuccess();
        // dispatch(filterActivities({ filter, eventType, page: 0 }));
      }
    } catch (error) {
      console.error('Liking failed:', error);

      setIsLiked(s => !s);
    }
  };
  useEffect(() => {
    if (activity?.bookMark !== undefined) {
      setIsBookmarked(activity?.bookMark);
    }
  }, [activity?.bookMark]);

  useEffect(() => {
    if (activity?.liked !== undefined) {
      setIsLiked(activity.liked);
    }
  }, [activity?.liked]);

  useEffect(() => {
    if (activity?.likeCount !== undefined) {
      setLikeCountNumber(activity?.likeCount);
    }
  }, [activity?.likeCount]);

  const [isVisible, setisVisible] = useState(false);
  useEffect(() => {
    if (activity?.hasPermission) {
      setisVisible(true);
    }

    if (activity?.participant && !activity?.hasPermission) {
      setisVisible(true);
    }
    if (!activity?.hasPermission && !activity?.reported) {
      setisVisible(true);
    }
    if (!activity?.hasPermission && activity?.reported) {
      setisVisible(true);
    }
  }, [activity?.hasPermission, activity?.activityStatus, activity?.reported]);

  const handleReport = () => {
    handleClose();

    setReportDialogOpen(true);
  };

  const handleRemoteActivityClose = () => {
    setOpenRemoteModal(false);
  };

  const fetchData = async (id, type) => {
    if (activity?.recurringId !== undefined) {
      try {
        const response = await dispatch(fetchRecurringDetailsFilter({ id: id, filterType: type }));
        // Access the response data here()
        if (response?.payload && Array.isArray(response.payload)) {
          const sortedPayload = response?.payload?.sort((a, b) => {
            if (a.parent && !b.parent) {
              return -1;
            } else if (!a.parent && b.parent) {
              return 1;
            }
            return 0;
          });
          setChildrens(sortedPayload);
        }
        console.log('Recurring details response:', response?.payload);
      } catch (error) {
        console.error('Recurring failed:', error);
      }
    }
  };

  const handleButtonClick = (id, title) => {
    navigate(`/recurringactivity/${title}-${id}`);
    dispatch(updatePrevItemId(id));
    fetchViewCount(id);
  };

  const fetchViewCount = async (id: string) => {
    const myDetailsAccount = localStorage.getItem('MyDetailsAccount');
    const userId = myDetailsAccount ? JSON.parse(myDetailsAccount)?.id : null;
    try {
      if (isAuthenticated) {
        const response = await axios.post<any>(`api/activities/${id}/add-view?userId=${userId}`);
      } else {
        const response = await axios.post<any>(`api/unauthorized/activities/${id}/add-view`);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleModalClose = () => {
    setOpenModal(false);
  };

  const setLeaveFalse = () => {
    setLeaveDialogOpen(false);
    setSelectedLeaveOptions([]);
    setLeaveActivityAnchorEl(null);
    setAnchorEl(null);
    fetchParticipantChildrenStatus();
    fetchParticipantStatus();
    if (fromProfile) {
      dispatch(fetchProfileDetails({ ...commonParams, eventType: 'Bookmarked' }));
    }
  };

  const sentParameters = async data => {
    if (!isAuthenticated) {
      setCurrentComponent('login');
      dispatch(toggleModalLogin());

      setAuthModalOpen(true);
    } else {
      const { id, eventType } = data;
      const storageData = { id, eventType };
      if (localStorage.getItem('dataKey')) {
        localStorage.removeItem('dataKey');
      }
      localStorage.setItem('dataKey', JSON.stringify(storageData));
      navigate('/chat');
    }
  };

  const getShareToken = (socialName, content, activityId, type, URL, shareMessage) => {
    console.log(socialName, content, activityId, type, URL, shareMessage, 'socialName, URL, shareMessage');

    dispatch(fetchActivityShareToken({ activityId, type }))
      .then(result => {
        const token = result.payload.token;

        if (token) {
          const utmSource = socialName;
          const utmCampaign = 'Reachamy';
          const utmContent = content.replace(/\s/g, '');

          const newURL = `${URL}?utm_source=${utmSource}&utm_campaign=${utmCampaign}&utm_content=${utmContent}&t=${token}`;

          shareOnSocialMedia(socialName, newURL, shareMessage);
        }
      })
      .catch(err => {
        console.error('Error fetching data:', err);
        toast.error('Something went wrong');
      });
  };
  const [openShare, setOpenShare] = useState(false);
  const handleModalCloseShare = () => {
    setOpenShare(false);
  };

  const handleClickShare = () => {
    setOpenShare(true);
  };

  const [openInviteModal, setOpenInviteModal] = useState(false);

  const [webUrl, setwebUrl] = useState(activity?.eventURL ? activity?.eventURL : '');

  const handleInviteModalClose = () => {
    setOpenInviteModal(false);
  };
  const [phoneVerificationStatus, setPhoneVerificationStatus] = useState(true);
  const [phoneInputCompleted, setPhoneInputCompleted] = useState(false);
  const [otpVerificationCompleted, setOtpVerificationCompleted] = useState(false);
  const fetchPhoneNumber = async () => {
    try {
      const response = await dispatch(getPhoneNumber());
      if (response.meta.requestStatus == 'fulfilled') {
        localStorage.setItem('getPhone', JSON.stringify(response.payload));
        setPhoneVerificationStatus(response.payload?.verified);
      } else {
        // toast.error('Unable to fetch verification Status', { id: 'phone-verification-status-error' });
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (localStorage.getItem('getPhone') && localStorage.getItem('getPhone') !== undefined) {
      const phoneDataString = localStorage.getItem('getPhone');
      if (phoneDataString) {
        try {
          const phoneData = JSON.parse(phoneDataString);
          if (typeof phoneData.verified === 'boolean') {
            setPhoneVerificationStatus(phoneData.verified);
          } else {
            console.error('Verified status is missing or invalid in localStorage data');
          }
        } catch (error) {
          console.error('Failed to parse phone data from localStorage:', error);
        }
      } else {
        console.log('No phone data found in localStorage');
      }
    }
  }, [localStorage.getItem('getPhone')]);

  const handlePhoneVerificationClose = () => {
    setPhoneVerificationModal(false);
    if (otpVerificationCompleted) {
      fetchPhoneNumber();
    } else {
      setPhoneInputCompleted(false);
    }
  };

  const [index, setIndex] = useState(-1);
  const handleLightBoxClick = (index: number) => setIndex(index);
  const handleLightBoxClose = () => setIndex(-1);

  const slides: GallerySlide[] = activity?.eventGallery?.map(img => {
    if (img.type === 'videos') {
      return {
        type: 'video',
        src: img?.link,
        thumbnail: img?.thumbnail,
        title: '',
        sources: [{ src: img?.link, type: 'video/mp4' }],
      };
    } else {
      return {
        type: 'image',
        src: img?.link,
        thumbnail: img?.link,
        title: '',
      };
    }
  });

  return (
    <Card
      key={activity?.recurringId}
      id={activity?.id}
      sx={{
        minWidth: '100%',
        backgroundColor: 'secondary.dark',
        boxShadow: 'none',
        borderRadius: '20px',
      }}
    >
      <style>
        {`.css-cveggr-MuiListItemIcon-root {
            min-width: 39px !important;
          }
        `}

        {`.css-f7exwm-MuiButtonBase-root-MuiListItemButton-root  {
                padding-left:0px !important;
                 padding-right:0px !important;
                     padding-bottom: 0px !important;
            }`}
        {`.css-qank3n-MuiListItem-root  {
                padding-left:0px !important;
                 padding-right:8px !important;
                  padding-bottom: 0px !important;
            }`}

        {`.css-1renlu2-MuiTypography-root  {
                word-break:break-word;
            }`}

        {`.css-i68wbg-MuiListItemText-root {
                        padding-left:13px !important;
                    }`}

        {`.css-rkj4ng-MuiListItem-root{
            padding-left:0px !important;
                 padding-right:0px !important;
                  padding-bottom: 0px !important;
                   padding-top: 0px !important;
        }`}
      </style>

      <>
        <div style={{ height: '430px', position: 'relative', borderRadius: '15px', overflow: 'hidden' }}>
          {activity?.featured && (
            <div
              style={{
                position: 'absolute',
                top: '0',
                left: '0',
                background: '#674BFF',
                color: '#FFFF',
                padding: '5px 10px',
                borderRadius: '0 0 15px 0',
                zIndex: '999',
                fontSize: '12px',
              }}
            >
              <span style={{ fontWeight: 'bold' }}>Featured</span>
            </div>
          )}
          <ImageGrid onImageClick={handleLightBoxClick}>
            {activity?.eventGallery?.map(item => (
              <div className={`img_container ${item.type === 'videos' ? 'video-grid' : ''}`}>
                <img alt="ig" src={item.type === 'videos' ? item?.thumbnail : item?.link} />
              </div>
            ))}
          </ImageGrid>
          <div
            className="legend d-flex justify-content-between align-items-center paddingLeft: '23px'"
            style={{
              position: 'absolute',
              background: 'rgba(0, 0, 0, 0.3)',
              right: 0,
              left: 0,
              bottom: 0,
              height: '40px',
              paddingLeft: '23px',
            }}
          >
            <div
              style={{
                position: 'relative',
                left: ' 15px',
              }}
            >
              <span className="d-flex  align-items-center ">
                {activity?.eventParticipantsCount > 0 && (
                  <>
                    <AvatarGroup max={3} className="av-size" style={{ height: '20px', width: '20px', fontSize: 12 }}>
                      {activity?.participants.map((item): any => (
                        <Avatar
                          key={item?.id}
                          src={item?.profilePic}
                          alt={`${item?.profilePic}` ? '' : getInitials(item?.name)}
                          style={{
                            height: '20px',
                            width: '20px',
                            zIndex: '2',
                            fontSize: item?.profilePic ? 'default' : '12px',
                          }}
                        >
                          {getInitials(item?.name)}
                        </Avatar>
                      ))}
                    </AvatarGroup>
                    <p style={{ color: '#ffff', marginRight: '10px', marginLeft: '10px', fontSize: '11px', marginBottom: '0px' }}>
                      {activity?.eventParticipantsCount}
                    </p>

                    <Box
                      sx={{
                        width: '5px',
                        height: '5px',
                        borderRadius: '50%',
                        backgroundColor: '#ffff',
                      }}
                    />
                  </>
                )}

                {activity?.ageLimit !== null && activity?.ageLimit !== 0 && activity?.lostPet === null && (
                  <p
                    style={{ color: '#ffff', marginLeft: '10px', fontSize: '11px', marginBottom: '0px' }}
                  >{`age limit ${activity?.ageLimit}+`}</p>
                )}
              </span>
            </div>

            <div>
              {isBookmarked ? (
                <BookmarkIcon
                  sx={{
                    color: '#674BFF',
                    fontSize: '24px',
                    marginRight: '16px',
                    cursor: 'pointer',
                  }}
                  onClick={handleBookmarkClick}
                />
              ) : (
                <BookmarkBorderIcon
                  sx={{
                    color: '#ffff',
                    fontSize: '24px',
                    marginRight: '16px',
                    cursor: 'pointer',
                  }}
                  onClick={handleBookmarkClick}
                />
              )}
            </div>
          </div>
        </div>
        <Lightbox
          carousel={{ finite: true }}
          open={index >= 0}
          close={handleLightBoxClose}
          slides={slides}
          plugins={[Video]}
          index={index}
        />
      </>

      <div
        style={{
          paddingLeft: isSmallerScreen ? '8px' : '16px',
          paddingRight: isSmallerScreen ? '8px' : '16px',
          paddingTop: isSmallerScreen ? '8px' : '1px',
          paddingBottom: isSmallerScreen ? '8px' : '1px',
        }}
      >
        <div className="row d-flex justify-content-between ">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="flex-start"
            pt={1.6}
            onClick={() => handleButtonClick(activity?.id, encodedTitle(activity?.title))}
          >
            <Box display="flex" justifyContent="space-between" alignItems="center" gap={1} onClick={e => e.stopPropagation()}>
              <Avatar
                onClick={() =>
                  activity?.postAsOrganisation
                    ? navigate(`/organization/${activity.organizationId}`)
                    : navigate(`/profile/${activity?.createdBy}`)
                }
                alt={`${activity?.postedByProfileImage}` ? '' : getInitials(activity?.postedBy)}
                src={activity?.postedByProfileImage}
                sx={{ width: '30px', height: '29px', cursor: 'pointer', fontSize: activity?.postedByProfileImage ? 'default' : '12px' }}
              >
                {' '}
                {getInitials(activity?.postedBy)}
              </Avatar>
              <Box display="flex" gap={1.6}>
                <Box marginX={0.6} display="inline-flex" component="span" gap={0.5} alignItems="baseline">
                  <Link
                    style={{ fontWeight: 600, width: '100%', wordBreak: 'break-word' }}
                    to={activity?.postAsOrganisation ? `/organization/${activity.organizationId}` : `/profile/${activity?.createdBy}`}
                  >
                    {isSmallDevice && activity?.postedBy?.length > 36 ? truncateString(activity?.postedBy, 15) : activity?.postedBy}
                    <Typography
                      component="span"
                      className="text-lowercase"
                      sx={{
                        font: 'normal normal normal 13px/21px Manrope',
                        color: '#9FA1A4',
                        mx: 0.6,
                        display: 'inline-block',
                      }}
                    >
                      <Moment fromNow>{activity?.createdAt}</Moment>
                      {!activity?.makeThisPrivateActivity && (
                        <Tooltip title="This post is visible to anyone on Reach AMY ">
                          <a className="ms-2" style={{ cursor: 'default', color: 'rgb(159, 161, 164)' }}>
                            <LanguageIcon />
                          </a>
                        </Tooltip>
                      )}
                    </Typography>
                  </Link>
                </Box>
              </Box>

              {/* <Box display="flex" gap={1.6} alignItems="center">
                <Typography variant="body1" display="flex" gap={1} alignItems="baseline">
                  <Link
                    style={{ fontWeight: 600 }}
                    to={activity?.postAsOrganisation ? `/organization/${activity.organizationId}` : `/profile/${activity?.createdBy}`}
                  >
                    {isSmallDevice && activity?.postedBy?.length > 36 ? truncateString(activity?.postedBy, 15) : activity?.postedBy}
                  </Link>
                  <Box ml={0.6} display="inline-flex" component="span" gap={0.5} alignItems="baseline">
                    <Typography
                      component="span"
                      className="text-lowercase"
                      sx={{ font: 'normal normal normal 13px/21px Manrope', color: '#9FA1A4' }}
                    >
                      <Moment fromNow>{activity?.createdAt}</Moment>
                    </Typography>
                    {!activity?.makeThisPrivateActivity && (
                      <Tooltip title="This post is visible to anyone on Reachamy ">
                        <a style={{ cursor: 'default', color: 'rgb(159, 161, 164)' }}>
                          <LanguageIcon />
                        </a>
                      </Tooltip>
                    )}
                  </Box>
                </Typography>
              </Box> */}
            </Box>
            <Box display="flex" flexDirection="column" alignItems="flex-end">
              <Box
                aria-label="notifications"
                sx={{
                  padding: 'unset',
                  cursor: 'pointer',
                  display: 'block',
                  '&:hover': {
                    backgroundColor: 'inherit',
                  },
                }}
                onClick={e => {
                  e.stopPropagation();
                  handleRemoteActivityButton();
                }}
              >
                {activity?.online ? (
                  <Tooltip title={toolTipText}>
                    <Box display="flex" alignItems="center">
                      <RemoteEventIcon sx={{ fontSize: '16px' }} />
                      <small
                        style={{
                          overflow: 'hidden',
                          whiteSpace: 'nowrap',
                          // textOverflow: 'ellipsis',
                          width: '90px',
                          marginLeft: '3px',
                          ...(isMdScreen ? locationXs : location),
                        }}
                      >
                        Remote event
                      </small>
                    </Box>
                  </Tooltip>
                ) : (
                  <Link
                    style={{ display: 'flex', alignItems: 'center' }}
                    className="text-decoration-none"
                    target="_blank"
                    rel="noopener noreferrer"
                    to={
                      activity?.eventPlace
                        ? generateGMapsLinkPlace(activity?.eventPlace)
                        : activity.latitude && activity?.longitude
                        ? generateGMapsLinkCords(activity?.latitude, activity?.longitude)
                        : '#'
                    }
                  >
                    <LocationOnOutlinedIcon sx={{ color: isDarkMode ? '#BFBFBF' : '#5A5F67' }} />

                    {(places?.place || activity?.eventPlace) && (
                      <Tooltip title={(places?.place || activity?.eventPlace) ?? null}>
                        <small
                          style={{
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                            width: '79px',
                            ...(isMdScreen ? locationXs : location),
                          }}
                        >
                          {/* {activity?.eventPlace?.length > 13
                            ? activity?.eventPlace?.split(',')[0]?.length > 12
                              ? `${activity?.eventPlace?.split(',')[0]?.substring(0, 10)}...`
                              : activity?.eventPlace?.split(',')[0]
                            : activity?.eventPlace} */}
                          {activity?.eventPlace?.length > 13
                            ? activity?.eventPlace?.split(',')[0]?.length > 12
                              ? `${activity?.city},${activity?.country}`
                              : `${activity?.city},${activity?.country}`
                            : activity?.eventPlace}
                        </small>
                      </Tooltip>
                    )}
                  </Link>
                )}
              </Box>
            </Box>
          </Box>

          <Box mt="6px" display="flex" justifyContent="space-between" alignItems="flex-end">
            <Box display="flex" alignItems="center" gap="6px" flexWrap="wrap">
              {activity?.category?.id === 19 && (
                <Button
                  className="btn-urg"
                  variant="contained"
                  sx={{
                    fontSize: '13px',
                    borderRadius: '20px',
                    height: '28px',
                    backgroundColor: theme.palette.mode === 'dark' ? '#3D3C52' : '#FF9494',
                    color: theme.palette.mode === 'dark' ? '#FF93A9' : '#710017',
                    boxShadow: 'none',
                    cursor: 'auto',
                    '&:hover': {
                      color: theme.palette.mode === 'dark' ? '#FF93A9' : '#710017',
                      backgroundColor: theme.palette.mode === 'dark' ? '#3D3C52' : '#FF9494',
                    },
                  }}
                >
                  Urgent
                </Button>
              )}
              {activity?.category?.id !== 19 && (
                <Button
                  sx={{
                    fontSize: '13px',
                    borderRadius: '20px',
                    height: '28px',
                    whiteSpace: 'nowrap',
                    cursor: 'auto',
                    backgroundColor: theme.palette.mode === 'dark' ? '#3D3C52' : '#F6F7F8',
                    transition: 'background-color 0s ease',
                    color: theme.palette.mode === 'dark' ? 'secondary.contrastText' : '#362691',
                  }}
                >
                  {activity?.category?.category}
                </Button>
              )}
              <Button
                sx={{
                  fontSize: '13px',
                  borderRadius: '20px',

                  height: '28px',
                  whiteSpace: 'nowrap',
                  cursor: 'auto',
                  backgroundColor: theme.palette.mode === 'dark' ? '#3D3C52' : '#F6F7F8',
                  transition: 'background-color 0s ease',
                  color: theme.palette.mode === 'dark' ? 'secondary.contrastText' : '#362691',
                }}
              >
                Recurring
              </Button>
            </Box>
            <Box
              component="span"
              sx={{ font: '14px / 21px Manrope', color: 'rgb(103, 110, 123)', width: 'max-content', textAlign: 'right' }}
            >
              Starts on {convertDateToYear(activity?.recurringStartDate)}
              <br></br> Ends on {convertDateToYear(activity?.recurringEndDate)}
            </Box>
          </Box>

          <div className="col-6"></div>
          <CardContent
            onClick={() => {
              handleButtonClick(activity?.id, encodedTitle(activity?.title));
            }}
            sx={{ paddingBottom: '0px' }}
          >
            <div
              style={{
                font: ' normal normal 600 24px/32px Manrope',
                textAlign: 'left',
                letterSpacing: 0,
                cursor: 'pointer',
              }}
            >
              {activity?.title}
            </div>
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                color: theme.palette.secondary.light,
                display: '-webkit-box',
                WebkitBoxOrient: 'vertical',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                maxHeight: '6em',
                lineClamp: 3,
                WebkitLineClamp: 3,
                cursor: 'pointer',
              }}
            >
              <>
                {activity?.caption ? (
                  <>
                    <span
                      style={{
                        color: theme.palette.mode === 'dark' ? theme.palette.secondary.contrastText : '#5A5F67',
                        cursor: 'pointer',
                      }}
                    >
                      {activity?.caption}
                    </span>

                    {activity?.caption?.length > 220 && <span style={{ cursor: 'pointer' }}>... Read more</span>}
                  </>
                ) : null}
              </>
            </Typography>
          </CardContent>
          <CardActions sx={{ marginBottom: '-23px', pr: 2 }}>
            <div className="row" style={{ width: '105%' }}>
              <div className="col-sm-12 col-md-12 col-lg-7">
                <div className=" d-flex">
                  <span className=" d-flex mt-3 mb-3 ms-2 me-2">
                    {isLiked ? (
                      <UpVoteFilled sx={{ cursor: 'pointer', fontSize: '19px' }} onClick={handleLikeClick} />
                    ) : (
                      <UpVoteOutlined sx={{ cursor: 'pointer', fontSize: '19px' }} onClick={handleLikeClick} />
                    )}

                    <p className="px-2 " style={{ fontSize: '14px' }}>
                      {likeCountNumber ?? 0}
                    </p>
                  </span>
                  <span className=" d-flex mt-3 mb-3 ms-2 me-2" onClick={() => sentParameters(activity)}>
                    <SquareChatIcon style={{ cursor: 'pointer', fontSize: '19px' }} />
                    <p className="px-1 px-md-2" style={{ fontSize: '14px' }}>
                      {activity?.chatMsgCount}
                    </p>
                  </span>

                  <span className=" d-flex mt-3 mb-3 ms-2 me-2">
                    <VisibilityIcon style={{ cursor: 'pointer', fontSize: '19px', color: '#676E7B' }} />
                    <p className="px-md-2 px-1" style={{ fontSize: '14px' }}>
                      {activity?.activityViewCount}
                    </p>
                  </span>
                  <span className=" d-flex mt-3 mb-3 ms-2 me-2" onClick={handleShareButtonClick}>
                    <ShareIcon aria-describedby={socialShareId} style={{ cursor: 'pointer', fontSize: '19px', color: '#676E7B' }} />
                    <p className="px-md-2 px-1"></p>
                  </span>
                  <Popover
                    id={socialShareId}
                    open={Boolean(socialAnchorEl)}
                    anchorEl={socialAnchorEl}
                    onClose={handleSocialShareClose}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                  >
                    <ListItem sx={{ display: 'flex', flexDirection: 'column' }}>
                      {theme.palette.mode === 'dark' ? (
                        <BlueTwitterIcon
                          sx={{ cursor: 'pointer' }}
                          onClick={() =>
                            getShareToken(
                              'twitter',
                              activity?.title.slice(0, 10),
                              activity?.id,
                              1,
                              activity?.eventURL,
                              appConstants.SocialShareMessage
                            )
                          }
                        />
                      ) : (
                        <GrayTwitterIcon
                          sx={{ cursor: 'pointer' }}
                          onClick={() =>
                            getShareToken(
                              'twitter',
                              activity?.title.slice(0, 10),
                              activity?.id,
                              1,
                              activity?.eventURL,
                              appConstants.SocialShareMessage
                            )
                          }
                        />
                      )}
                    </ListItem>

                    <ListItem sx={{ display: 'flex', flexDirection: 'column' }}>
                      <FacebookIcon
                        sx={{ color: theme.palette.mode === 'dark' ? '#1D9BF0' : '#676e7b', cursor: 'pointer' }}
                        onClick={() =>
                          getShareToken(
                            'facebook',
                            activity?.title.slice(0, 10),
                            activity?.id,
                            2,
                            activity?.eventURL,
                            appConstants.SocialShareMessage
                          )
                        }
                      />
                    </ListItem>

                    <ListItem sx={{ display: 'flex', flexDirection: 'column' }}>
                      <LinkedInIcon
                        sx={{ color: theme.palette.mode === 'dark' ? '#1D9BF0' : '#676e7b', cursor: 'pointer' }}
                        onClick={() =>
                          getShareToken(
                            'linkedIn',
                            activity?.title.slice(0, 10),
                            activity?.id,
                            3,
                            activity?.eventURL,
                            appConstants.SocialShareMessage
                          )
                        }
                      />
                    </ListItem>
                  </Popover>
                  {isAuthenticated && isVisible && (
                    <span className=" d-flex ms-2 mt-3 mb-3 " onClick={handleClick}>
                      <MoreHorizOutlinedIcon
                        aria-describedby={'menuItemId'}
                        sx={{ cursor: 'pointer', fontSize: '19px', color: '#676E7B' }}
                      />
                    </span>
                  )}

                  {activity?.hasPermission === true && (
                    <Popover
                      id={menuItemId}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                    >
                      <ListItem sx={{ display: 'flex', flexDirection: 'column', textAlign: 'left', alignItems: 'inherit', px: 1 }}>
                        <>
                          <button
                            style={{ padding: 0, background: 'none', border: 'none', cursor: 'pointer', textAlign: 'left' }}
                            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                              handleEditActivityClick(event);
                            }}
                          >
                            <ListItemText sx={{ padding: 0 }} className="text-start">
                              <Typography sx={{ p: 0, fontSize: '14px', cursor: 'pointer', color: isDarkMode ? '#fff' : '#000' }}>
                                Edit activity
                              </Typography>
                            </ListItemText>
                          </button>
                          <button
                            style={{ padding: 0, background: 'none', border: 'none', cursor: 'pointer', textAlign: 'left' }}
                            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                              handleDeleteActivityClick(event);
                            }}
                          >
                            <ListItemText sx={{ padding: 0 }}>
                              <Typography sx={{ p: 0, cursor: 'pointer', fontSize: '14px', color: isDarkMode ? '#fff' : '#000' }}>
                                Delete activity
                              </Typography>
                            </ListItemText>
                          </button>
                          {activity?.permittedToFeatured === true && (
                            <button
                              style={{ padding: 0, background: 'none', border: 'none', cursor: 'pointer', textAlign: 'left' }}
                              onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                handleFeatureClick(event, activity);
                              }}
                            >
                              <ListItemText sx={{ padding: 0 }} className="text-start">
                                <Typography sx={{ p: 0, fontSize: '14px', cursor: 'pointer', color: isDarkMode ? '#fff' : '#000' }}>
                                  Feature activity
                                </Typography>
                              </ListItemText>
                            </button>
                          )}
                        </>
                      </ListItem>
                    </Popover>
                  )}

                  {!activity?.hasPermission && (
                    <Popover
                      id={menuItemId}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                    >
                      <ListItem
                        sx={{ display: 'flex', flexDirection: 'column', textAlign: 'left', alignItems: 'inherit', padding: '8px 16px' }}
                      >
                        {activity?.showLeaveActivity && activity?.activityStatus === 'OPEN' && (
                          <button
                            style={{ padding: 0, background: 'none', border: 'none', cursor: 'pointer' }}
                            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                              handleLeaveActivityClick(event);
                            }}
                          >
                            <ListItemText sx={{ padding: 0 }} className="text-center">
                              <Typography sx={{ p: 0, cursor: 'pointer', fontSize: '14px', color: isDarkMode ? '#fff' : '#000' }}>
                                Leave activity
                              </Typography>
                            </ListItemText>
                          </button>
                        )}

                        {/* view report activity option  */}

                        {!activity?.hasPermission && !activity?.reported ? (
                          <ListItemText sx={{ padding: 0 }} onClick={() => handleReport()} className="text-center">
                            <Typography sx={{ p: 0, cursor: 'pointer', fontSize: '14px' }}>Report activity</Typography>
                          </ListItemText>
                        ) : !activity?.hasPermission && activity?.reported ? (
                          <ListItemText sx={{ padding: 0 }} className="text-center">
                            <Typography sx={{ textAlign: 'left', p: 0, cursor: 'pointer', fontSize: '14px' }}>Reported</Typography>
                          </ListItemText>
                        ) : null}

                        {activity?.permittedToFeatured === true && (
                          <button
                            style={{ padding: 0, background: 'none', border: 'none', cursor: 'pointer' }}
                            onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                              handleFeatureClick(event, activity);
                            }}
                          >
                            <ListItemText sx={{ padding: 0 }} className="text-start">
                              <Typography sx={{ p: 0, fontSize: '14px', cursor: 'pointer', color: isDarkMode ? '#fff' : '#000' }}>
                                Feature activity
                              </Typography>
                            </ListItemText>
                          </button>
                        )}
                      </ListItem>
                    </Popover>
                  )}
                  <Popover
                    id={editActivityId}
                    open={openOptions}
                    anchorEl={editActivityAnchorEl}
                    onClose={handleEditActivityClose}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                  >
                    <Box
                      sx={{
                        maxHeight: '150px',
                        overflowY: 'auto',
                        scrollbarWidth: 'thin',
                        '-ms-overflow-style': 'none',
                        '&::-webkit-scrollbar': {
                          width: '8px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                          background: '#888',
                        },
                      }}
                    >
                      <ListItem
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          textAlign: 'left',
                          alignItems: 'inherit',
                        }}
                      >
                        {childrens?.map(option => {
                          const datePart = convertDateToFormattedString(option?.startDate);
                          const timePart1 = getFormattedTime(option?.startTime);
                          const timePart2 = getFormattedTime(option?.endTime);
                          const isDisabled = option?.disable;
                          const tooltipText = 'This activity has participant';

                          return (
                            <Tooltip title={isDisabled ? tooltipText : ''} arrow>
                              <span>
                                <ListItemButton
                                  disabled={isDisabled ? true : false}
                                  key={option?.id}
                                  onClick={() => handleOptionClick(option)}
                                >
                                  <ListItemIcon>
                                    <Checkbox checked={selectedOptions.includes(option?.id)} disabled={isDisabled ? true : false} />
                                  </ListItemIcon>
                                  {!option?.parent ? (
                                    <Typography sx={{ p: 0, cursor: 'pointer', fontSize: '13px' }}>
                                      <span style={{ fontWeight: 'bold' }}>{datePart}</span>
                                      <br />
                                      {timePart1}-{timePart2}
                                    </Typography>
                                  ) : (
                                    <Typography sx={{ p: 0, cursor: 'pointer', fontSize: '13px' }}>
                                      <span style={{ fontWeight: 'bold' }}>{'Select all'}</span>
                                    </Typography>
                                  )}
                                </ListItemButton>
                              </span>
                            </Tooltip>
                          );
                        })}
                      </ListItem>
                    </Box>
                    <div style={{ display: 'flex', padding: '10px' }}>
                      <ListItemButton
                        onClick={handleOkButtonClick}
                        disabled={selectedOptions?.length === 0}
                        sx={{
                          backgroundColor: '#FFCE00',
                          color: 'black',
                          borderRadius: '15px',
                          '&:hover': {
                            backgroundColor: '#FFCE00',
                          },
                        }}
                      >
                        <ListItemText sx={{ p: 0, cursor: 'pointer', fontSize: '14px', textAlign: 'center' }}>Submit</ListItemText>
                      </ListItemButton>
                    </div>
                  </Popover>
                  <Popover
                    id={leaveActivityId}
                    open={openLeaveOptions}
                    anchorEl={leaveActivityAnchorEl}
                    onClose={handleLeaveActivityClose}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                  >
                    <Box
                      sx={{
                        maxHeight: '150px',
                        overflowY: 'auto',
                        scrollbarWidth: 'thin',
                        '-ms-overflow-style': 'none',
                        '&::-webkit-scrollbar': {
                          width: '8px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                          background: '#888',
                        },
                      }}
                    >
                      <ListItem
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          textAlign: 'left',
                          alignItems: 'inherit',
                        }}
                      >
                        {childrens?.map(option => {
                          const datePart = convertDateToFormattedString(option?.startDate);
                          const timePart1 = getFormattedTime(option?.startTime);
                          const timePart2 = getFormattedTime(option?.endTime);

                          return (
                            <ListItemButton key={option?.id} onClick={() => handleOptionLeaveClick(option)}>
                              <ListItemIcon>
                                <Checkbox checked={selectedLeaveOptions.includes(option?.id)} />
                              </ListItemIcon>
                              {!option?.parent ? (
                                <Typography sx={{ p: 0, cursor: 'pointer', fontSize: '13px' }}>
                                  <span style={{ fontWeight: 'bold' }}>{datePart}</span>
                                  <br />
                                  {timePart1}-{timePart2}
                                </Typography>
                              ) : (
                                <Typography sx={{ p: 0, cursor: 'pointer', fontSize: '13px' }}>
                                  <span style={{ fontWeight: 'bold' }}>{'Select all'}</span>
                                </Typography>
                              )}
                            </ListItemButton>
                          );
                        })}
                      </ListItem>
                    </Box>
                    <div style={{ display: 'flex', padding: '10px' }}>
                      <ListItemButton
                        onClick={handleLeaveButtonClick}
                        disabled={selectedLeaveOptions?.length === 0}
                        sx={{
                          backgroundColor: '#FFCE00',
                          color: 'black',
                          borderRadius: '15px',
                          '&:hover': {
                            backgroundColor: '#FFCE00',
                          },
                        }}
                      >
                        <ListItemText sx={{ p: 0, cursor: 'pointer', fontSize: '14px', textAlign: 'center' }}>Submit</ListItemText>
                      </ListItemButton>
                    </div>
                  </Popover>
                  <Popover
                    id={deleteActivityId}
                    open={openDeleteOptions}
                    anchorEl={deleteActivityAnchorEl}
                    onClose={handleDeleteActivityClose}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                  >
                    <Box
                      sx={{
                        maxHeight: '150px',
                        overflowY: 'auto',
                        scrollbarWidth: 'thin',
                        '-ms-overflow-style': 'none',
                        '&::-webkit-scrollbar': {
                          width: '8px',
                        },
                        '&::-webkit-scrollbar-thumb': {
                          background: '#888',
                        },
                      }}
                    >
                      <ListItem
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          textAlign: 'left',
                          alignItems: 'inherit',
                        }}
                      >
                        {childrens?.map(option => {
                          const datePart = convertDateToFormattedString(option?.startDate);
                          const timePart1 = getFormattedTime(option?.startTime);
                          const timePart2 = getFormattedTime(option?.endTime);

                          return (
                            <ListItemButton key={option?.id} onClick={() => handleOptionDeleteClick(option)}>
                              <ListItemIcon>
                                <Checkbox checked={selectedDeleteOptions?.includes(option?.id)} />
                              </ListItemIcon>
                              {!option?.parent ? (
                                <Typography sx={{ p: 0, cursor: 'pointer', fontSize: '13px' }}>
                                  <span style={{ fontWeight: 'bold' }}>{datePart}</span>
                                  <br />
                                  {timePart1}-{timePart2}
                                </Typography>
                              ) : (
                                <Typography sx={{ p: 0, cursor: 'pointer', fontSize: '13px' }}>
                                  <span style={{ fontWeight: 'bold' }}>{'Select all'}</span>
                                </Typography>
                              )}
                            </ListItemButton>
                          );
                        })}
                      </ListItem>
                    </Box>
                    <div style={{ display: 'flex', padding: '10px' }}>
                      <ListItemButton
                        onClick={handleDeleteButtonClick}
                        disabled={selectedDeleteOptions?.length === 0}
                        sx={{
                          backgroundColor: '#FFCE00',
                          color: 'black',
                          borderRadius: '15px',
                          '&:hover': {
                            backgroundColor: '#FFCE00',
                          },
                        }}
                      >
                        <ListItemText sx={{ p: 0, cursor: 'pointer', fontSize: '14px', textAlign: 'center' }}>Submit</ListItemText>
                      </ListItemButton>
                    </div>
                  </Popover>
                </div>
              </div>

              <div
                className="col-sm-12 col-md-12 responsive-p col-lg-5 p-2 text-end"
                style={{ display: 'flex', justifyContent: 'flex-end' }}
                onClick={() => {
                  handleButtonClick(activity?.id, encodedTitle(activity?.title));
                }}
              >
                <span onClick={e => e.stopPropagation()}>
                  <>
                    {!isAuthenticated && new Date(activity?.recurringEndDate).getTime() > new Date().getTime() && (
                      <Button
                        variant="contained"
                        className="me-1"
                        sx={{
                          fontSize: '14px',
                          borderRadius: '20px',
                          height: '28px',
                          lineHeight: '12px',
                          '&:hover': {
                            backgroundColor: 'primary.main',
                            color: 'common.black',
                          },
                          width: 'fit-content',
                        }}
                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                          handleUnauthClick();
                        }}
                      >
                        {buttonText}
                      </Button>
                    )}
                    {new Date(activity?.recurringEndDate).getTime() < new Date().getTime() && !isAuthenticated && (
                      <Button
                        variant="text"
                        style={{
                          cursor: 'default',
                        }}
                        sx={{
                          fontSize: '14px',
                          borderRadius: '20px',
                          height: '28px',
                          textWrap: 'nowrap',
                          lineHeight: '12px',
                          color: isDarkMode ? '#FFCE00' : '#674BFF',
                          '&:hover': {
                            color: isDarkMode ? '#FFCE00' : '#674BFF',
                          },
                        }}
                      >
                        Pending feedback
                      </Button>
                    )}

                    {new Date(activity?.recurringEndDate).getTime() < new Date().getTime() && isAuthenticated && (
                      <Button
                        variant="text"
                        style={{
                          cursor: 'default',
                        }}
                        sx={{
                          fontSize: '14px',
                          borderRadius: '20px',
                          height: '28px',
                          textWrap: 'nowrap',
                          lineHeight: '12px',
                          color: isDarkMode ? '#FFCE00' : '#674BFF',
                          '&:hover': {
                            color: isDarkMode ? '#FFCE00' : '#674BFF',
                          },
                        }}
                      >
                        Pending feedback
                      </Button>
                    )}
                    {!activity?.participant &&
                      activity?.availableActivity &&
                      isAuthenticated &&
                      !activity?.hasPermission &&
                      activity?.recurringEndDate &&
                      new Date(activity?.recurringEndDate).getTime() > new Date().getTime() &&
                      !activity?.makeThisPrivateActivity && (
                        <Button
                          variant="contained"
                          className="me-1"
                          sx={{
                            fontSize: '14px',
                            borderRadius: '20px',
                            height: '28px',
                            lineHeight: '12px',
                            '&:hover': {
                              backgroundColor: 'primary.main',
                              color: 'common.black',
                            },
                            width: 'fit-content',
                          }}
                          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                            handleVolunteerOptionsClick(event);
                          }}
                        >
                          {buttonText}
                        </Button>
                      )}
                    {!activity?.participant &&
                      activity?.availableActivity &&
                      isAuthenticated &&
                      !activity?.hasPermission &&
                      activity?.recurringEndDate &&
                      new Date(activity?.recurringEndDate).getTime() > new Date().getTime() &&
                      activity?.makeThisPrivateActivity &&
                      activity?.invited && (
                        <Button
                          variant="contained"
                          className="me-1"
                          sx={{
                            fontSize: '14px',
                            borderRadius: '20px',
                            height: '28px',
                            lineHeight: '12px',
                            '&:hover': {
                              backgroundColor: 'primary.main',
                              color: 'common.black',
                            },
                            width: 'fit-content',
                          }}
                          onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                            handleVolunteerOptionsClick(event);
                          }}
                        >
                          {buttonText}
                        </Button>
                      )}
                    {!activity?.hasPermission &&
                      !activity?.invited &&
                      activity?.makeThisPrivateActivity &&
                      activity?.activityStatus == 'OPEN' && (
                        <Button
                          variant="text"
                          className="me-1"
                          style={{ cursor: 'default' }}
                          sx={{
                            color: isDarkMode ? '#FFCE00' : '#674BFF',
                            fontSize: '14px',
                            borderRadius: '20px',
                            height: '28px',
                            lineHeight: '12px',
                            '&:hover': {
                              backgroundColor: 'none',
                            },
                          }}
                        >
                          By invite only
                        </Button>
                      )}

                    <Popover
                      id={volunteerActivityId}
                      open={openVolunteer}
                      anchorEl={volunteerActivityAnchorEl}
                      onClose={handleVolunteerOptionsClose}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                    >
                      <Box
                        sx={{
                          maxHeight: '150px',
                          overflowY: 'auto',
                          scrollbarWidth: 'thin',
                          '-ms-overflow-style': 'none',
                          '&::-webkit-scrollbar': {
                            width: '8px',
                          },
                          '&::-webkit-scrollbar-thumb': {
                            background: '#888',
                          },
                        }}
                      >
                        <ListItem sx={{ display: 'flex', flexDirection: 'column', textAlign: 'left', alignItems: 'inherit' }}>
                          {/* Render options with checkboxes */}
                          {childrens?.map(option => {
                            const datePart = convertDateToFormattedString(option?.startDate);
                            const timePart1 = getFormattedTime(option?.startTime);
                            const timePart2 = getFormattedTime(option?.endTime);

                            return (
                              <ListItemButton key={option?.id} onClick={() => handleOptionVolunteerClick(option)}>
                                <ListItemIcon>
                                  <Checkbox checked={selectedVolunteerOptions.includes(option?.id)} />
                                </ListItemIcon>

                                {!option?.parent ? (
                                  <Typography sx={{ p: 0, cursor: 'pointer', fontSize: '13px' }}>
                                    <span style={{ fontWeight: 'bold' }}>{datePart}</span>
                                    <br />
                                    {timePart1}-{timePart2}
                                  </Typography>
                                ) : (
                                  <Typography sx={{ p: 0, cursor: 'pointer', fontSize: '13px' }}>
                                    <span style={{ fontWeight: 'bold' }}>{'Select all'}</span>
                                  </Typography>
                                )}
                              </ListItemButton>
                            );
                          })}
                          {/* Add "OK" button */}
                        </ListItem>
                      </Box>
                      <div style={{ display: 'flex', padding: '10px' }}>
                        <ListItemButton
                          onClick={handleOkVolunteerClick}
                          disabled={selectedVolunteerOptions?.length === 0}
                          sx={{
                            backgroundColor: '#FFCE00',
                            color: 'black',
                            borderRadius: '15px',
                            '&:hover': {
                              backgroundColor: '#FFCE00',
                            },
                          }}
                        >
                          <ListItemText sx={{ p: 0, cursor: 'pointer', fontSize: '14px', textAlign: 'center' }}>Submit</ListItemText>
                        </ListItemButton>
                      </div>
                    </Popover>
                    {activity?.hasPermission &&
                      activity?.recurringEndDate &&
                      new Date().getTime() < new Date(activity?.recurringEndDate).getTime() && (
                        <>
                          <Button
                            variant="contained"
                            className="me-1"
                            style={{ cursor: 'pointer' }}
                            sx={{
                              fontSize: '14px',
                              borderRadius: '20px',
                              height: '28px',
                              lineHeight: '12px',
                              '&:hover': {
                                backgroundColor: 'primary.main',
                                color: 'common.black',
                              },
                              width: 'fit-content',
                            }}
                            onClick={() => setOpenInviteModal(true)}
                          >
                            Thanks! Invite More
                          </Button>
                          {/* <p
                        style={{
                          color: isDarkMode ? '#FFCE00' : '#674BFF',
                          fontSize: '14px',
                          cursor: 'pointer',
                        }}
                      >
                        <ScheduleSendOutlinedIcon onClick={handleClickShare} />
                      </p> */}
                        </>
                      )}
                    {activity?.partialParticipant &&
                      !activity?.availableActivity &&
                      !activity?.hasPermission &&
                      activity?.activityStatus == 'OPEN' && (
                        <Button
                          variant="text"
                          // className="me-1"
                          style={{ cursor: 'default' }}
                          sx={{
                            color: isDarkMode ? '#FFCE00' : '#674BFF',
                            fontSize: '14px',
                            borderRadius: '20px',
                            height: '28px',
                            lineHeight: '12px',
                          }}
                        >
                          Thanks for volunteering
                        </Button>
                      )}
                  </>
                </span>
              </div>
            </div>
          </CardActions>

          {selectedOrgId && isVolunteerModalOpen && clickedActivity === activity?.recurringId && VolunteerModal}
          <Dialog
            open={openRemoteModal}
            onClose={handleRemoteActivityClose}
            aria-labelledby="remote-activity-modal"
            PaperProps={{
              sx: {
                borderRadius: { xs: 0, sm: '14px' },
                boxShadow: '0px 3px 6px rgba(0, 0, 0, 0.16)',
                width: { lg: 400 },
              },
            }}
          >
            <Stack direction="column" sx={{ p: 2 }} spacing={3} minHeight={200}>
              <Stack direction="row" alignItems={'center'} justifyContent={'flex-end'}>
                <CloseIcon onClick={handleRemoteActivityClose} sx={{ cursor: 'pointer' }} />
              </Stack>

              <Typography variant="h4" textAlign={'center'}>
                Meeting Link
              </Typography>

              <Button
                variant="outlined"
                sx={{ borderColor: 'primary.light', py: 1, borderRadius: '20px', '&:hover': { borderColor: 'primary.light' } }}
              >
                <Link to={activity?.onlineUrl} target="_blank" rel="noopener noreferrer" onClick={handleRemote}>
                  <Stack direction="column" alignItems={'center'}>
                    <Typography
                      sx={{
                        wordBreak: 'break-word',
                        font: 'normal normal normal 14px/23px Manrope',
                        color: isDarkMode ? theme.palette.secondary.contrastText : '#676E7B',
                      }}
                    >
                      {activity?.onlineUrl}
                    </Typography>
                  </Stack>
                </Link>
              </Button>
            </Stack>
          </Dialog>
        </div>
      </div>

      <AuthorisationModal
        currentComponent={currentComponent}
        fullScreen={fullScreen}
        authModalOpen={authModalOpen}
        onClose={handleAuthModalClose}
        handleAuthModalClose={handleAuthModalClose}
        setCurrentComponent={setCurrentComponent}
      />
      <GenericModal
        handleClose={() => setConfirmDelete(false)}
        fullScreen={!isMobile && fullScreen}
        open={confirmDelete}
        title="Are you sure want to delete?"
        buttons={modalActionButtons}
      />
      <style>
        {`    .css-1vqmm9r-MuiPaper-root-MuiDialog-paper {
        border-radius: 15px !important;
    }`}
        {`    .css-10ep9ks-MuiPaper-root-MuiDialog-paper {
        border-radius: 15px !important;
    }`}
      </style>
      <style>
        {`.css-rsdpij-MuiPaper-root-MuiDialog-paper {
    border-radius: 15px !important;}`}
        {`.css-przs3r-MuiPaper-root-MuiDialog-paper {  border-radius: 15px !important;}`}
      </style>
      <JoiningSuccessModal
        handleClose={handleModalClose}
        fullScreen={fullScreen}
        open={openModal}
        url={'chat url'}
        title="Thank you for volunteering."
      />
      <ShareModal
        handleModalClose={handleModalCloseShare}
        fullScreen={fullScreen}
        open={openShare}
        onClose={handleModalCloseShare}
        id={activity?.recurringId}
        message="Share more"
      />
      <InviteMoreModal
        handleModalClose={handleInviteModalClose}
        fullScreen={fullScreen}
        open={openInviteModal}
        onClose={handleInviteModalClose}
        responseUrl={webUrl}
        pdfUrl={''}
        message="Invite  your friends"
      />
      <PhoneVerification
        handleClose={handlePhoneVerificationClose}
        open={phoneVerificationModal}
        fullScreen={isSmallerScreen}
        phoneInputCompleted={phoneInputCompleted}
        setPhoneInputCompleted={setPhoneInputCompleted}
        otpVerificationCompleted={otpVerificationCompleted}
        setotpVerificationCompleted={setOtpVerificationCompleted}
      />
      <LeaveActivityRecurringDialog open={leaveDialogOpen} onClose={() => setLeaveFalse()} handleData={selectedLeaveOptions} />
      <ReportActivityDialog open={reportDialogOpen} onClose={() => handleReportClose()} handleData={activity} />

      <FeatureDialog
        paymentLoading={paymentLoading}
        agreeAction={handlePayment}
        values={payload}
        open={confirmFeature}
        setFeatureOpen={setConfirmFeature}
      />
    </Card>
  );
};

export default SingleActivityReccuringCard;
